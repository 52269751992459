import React, { Component, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import 'rc-dropdown/assets/index.css';
import "../../../../assets/routine/css/admin/questionnaire.scss";
import "../../../../assets/routine/css/admin/style.css";
import { Link } from "react-router-dom";
import { BsTrash } from "react-icons/bs";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { FaChevronRight, FaChevronDown, FaTrash, FaChevronUp, FaPlus } from "react-icons/fa";
import Switch from "react-switch";
import ButtonIcon from "../../../../assets/routine/icons/button-icon.svg";
import TextIcon from "../../../../assets/routine/icons/text-icon.svg";
import NumberIcon from "../../../../assets/routine/icons/number-icon.svg";
import DateIcon from "../../../../assets/routine/icons/date-icon.svg";
import ListIcon from "../../../../assets/routine/icons/dropdown-icon.svg";
import DateTimeIcon from "../../../../assets/routine/icons/date-time-icon.svg";
import RadioIcon from "../../../../assets/routine/icons/radio-icon.svg";
import TemperatureIcon from "../../../../assets/routine/icons/temperature-icon.svg";
import CheckboxIcon from "../../../../assets/routine/icons/check-icon.svg";
import ImageIcon from "../../../../assets/routine/icons/picture-icon.svg";
import { fetchData } from './modules/actions';
import { companyServices } from './modules/services';
import { QuessionaireApi } from "./modules/api";
import { alert } from '../../../../utilities';
import RSelect from 'react-select';
import moment from "moment";
import { ovRoutineColor } from '../../../../config';

const styles = (theme) => ({
    tabbar: {
        borderBottom: "1px solid #e0e0e0"
    },
    inactiveBackground: {
        backgroundColor: "gray"
    }
});

function EditQuestionnaire(props) {
    const [loading, setLoading] = useState(false);
    const [usersBarIsOpen, setUsersBarIsOpen] = useState(false);
    const [assigned_form, setAssignedForm] = useState(null);
    const [template_name, setTemplateName] = useState("");
    const [template_name_error, setTemplateNameError] = useState("");
    const [location, setLocation] = useState("");
    const [location_error, setLocationError] = useState("");
    const [frequency, setFrequency] = useState("");
    const [frequency_error, setFrequencyError] = useState("");
    const [start_date, setStartDate] = useState("");
    const [start_date_error, setStartDateError] = useState("");
    const [end_date, setEndDate] = useState("");
    const [end_date_error, setEndDateError] = useState("");
    const [sections, setSections] = useState([]);
    const [selected_section, setSelectedSection] = useState(null);
    const [is_question_selected, setIsQuestionSelected] = useState(false);

    const [questionnaire_cards, setQuestionnaireCards] = useState([
        {
            id: 1,
            value: "",
            isClicked: true,
            answer: {
                type: "",
                question: {
                    mandatory: false,
                    enabled: true
                },
                responses: [{
                    id: 1,
                    value: "",
                    bg_color: "",
                    score: null,
                    default: false,
                    validation: {
                        min: 0,
                        max: 100
                    },
                    unit: "C"
                }],
                default_choice: "",
                question_help: "",
                picture: "",
                email_notification: false,
                action_plan: false,
                tags: []
            },
            start_date: "",
            end_date: ""
        }
    ]);

    const [selected_question, setSelectedQuestion] = useState(sections[0]?.sub_sections[0]?.questions[0]);
    const [tasks_error, setTasksError] = useState("");

    const [basicFieldsDropdownIsOpen, setBasicFieldsDropdownIsOpen] = useState(true);
    const [yourResponsesDropdownIsOpen, setYourResponsesDropdownIsOpen] = useState(false);
    const [popularResponsesDropdownIsOpen, setPopularResponsesDropdownIsOpen] = useState(false);

    const [sectionDropdownIsOpen, setSectionDropdownIsOpen] = useState(false);
    const [subsectionDropdownIsOpen, setSubsectionDropdownIsOpen] = useState(false);
    const [questionDropdownIsOpen, setQuestionDropdownIsOpen] = useState(true);
    const [responesDropdownIsOpen, setResponsesDropdownIsOpen] = useState(false);
    const [defaultChoiceDropdownIsOpen, setDefaultChoiceDropdownIsOpen] = useState(false);
    const [questionHelpDropdownIsOpen, setQuestionHelpDropdownIsOpen] = useState(false);
    const [addEditPictureDropdownIsOpen, setAddEditPictureDropdownIsOpen] = useState(false);
    const [emailNotificationDropdownIsOpen, setEmailNotificationDropdownIsOpen] = useState(false);
    const [actionPlanDropdownIsOpen, setActionPlanDropdownIsOpen] = useState(false);
    const [tagsDropdownIsOpen, setTagsDropdownIsOpen] = useState(false);

    const [locations, setLocations] = useState([]);
    const [selected_locations, setSelectedLocations] = useState([]);
    const [api_selected_locations, setApiSelectedLocations] = useState([]);
    const [api_selected_users, setApiSelectedUsers] = useState([]);
    const [location_users, setLocationUsers] = useState([]);
    const [users_loading, setUsersLoading] = useState(false);

    const [deleted_sections, setDeletedSections] = useState([]);
    const [deleted_sub_sections, setDeletedSubSections] = useState([]);
    const [deleted_tasks, setDeletedTasks] = useState([]);
    const [deleted_responses, setDeletedResponses] = useState([]);

    useEffect(() => {
        setLoading(true);
        var isLocationReload = props.locationData.length > 0 ? false : true;
        props.fetchData(isLocationReload);

        companyServices
            .fetchForm(props.match.params.id)
            .then(response => {
                if (response.data.success) {
                    let api_form = response.data.result.form;

                    setTemplateName(api_form.template_name);
                    setFrequency(api_form.frequency);
                    setStartDate(api_form.start_date);
                    setEndDate(api_form.end_date);
                    setAssignedForm(response.data.result.assigned_form);
                    setApiSelectedLocations(api_form.locations);

                    let final_sections = [];

                    api_form.sections.forEach((api_section, api_section_key) => {
                        let final_subsections = [];

                        api_section.sub_sections.forEach((api_subsection, api_subsection_key) => {
                            let final_tasks = [];

                            api_subsection.tasks.forEach((api_task, api_task_key) => {
                                let final_responses = [];

                                api_task.responses.forEach((api_response, api_response_key) => {
                                    final_responses = final_responses.concat({
                                        _id: api_response._id,
                                        id: api_response_key + 1,
                                        value: api_response.response,
                                        bg_color: "",
                                        score: api_response.score,
                                        default: api_response.default,
                                        validation: {
                                            min: api_response.temperature_validation.min,
                                            max: api_response.temperature_validation.max
                                        },
                                        unit: api_response.unit
                                    });
                                });

                                final_tasks = final_tasks.concat({
                                    _id: api_task._id,
                                    id: api_task_key + 1,
                                    value: api_task.question,
                                    isClicked: false,
                                    answer: {
                                        type: api_task.answer_type,
                                        question: {
                                            mandatory: api_task.q_mandatory,
                                            enabled: api_task.q_enabled
                                        },
                                        responses: final_responses,
                                        default_choice: api_task.default_choice,
                                        question_help: api_task.q_help,
                                        picture: "",
                                        email_notification: api_task.email_notification,
                                        action_plan: api_task.action_plan,
                                        tags: []
                                    },
                                    help_image: api_task.help_file,
                                    help_file_type: api_task.help_file_type
                                });
                            });

                            final_subsections = final_subsections.concat({
                                _id: api_subsection._id,
                                id: api_subsection_key + 1,
                                name: api_subsection.name,
                                enabled: api_subsection.enabled,
                                isClicked: false,
                                questions: final_tasks
                            });
                        });

                        final_sections = final_sections.concat({
                            _id: api_section._id,
                            id: api_section_key + 1,
                            name: api_section.name,
                            enabled: api_section.enabled,
                            help: api_section.help,
                            isClicked: false,
                            sub_sections: final_subsections
                        });
                    });

                    setSections(final_sections);
                    setLoading(false);
                }
            })
    }, []);

    useEffect(() => {
        let loc = [];
        props.locationData.forEach(l => {
            loc = loc.concat({
                label: l.name,
                value: l._id
            });
        });
        setLocations(loc);
    }, [props.locationData]);

    useEffect(() => {
        if (api_selected_locations.length > 0) {
            let final_locations = [];
            let selected_locs = [];

            props.locationData?.forEach(location => {
                api_selected_locations.forEach(selected_loc => {
                    if (location._id == selected_loc) {
                        final_locations = final_locations.concat({
                            id: selected_loc,
                            name: location.name
                        });

                        selected_locs = selected_locs.concat({
                            value: selected_loc,
                            label: location.name
                        });
                    }
                });
            });

            setSelectedLocations(selected_locs);

            companyServices
                .fetchUsers({
                    locations: final_locations
                })
                .then(response => {
                    if (response.data.success) {
                        let result = response.data.result;
                        result.forEach(l => {
                            l.roles.forEach(r => {
                                r.users.forEach(u => {
                                    var check_assigned_user = assigned_form?.filter(a => a.location_id == l.location_id && u.uid._id == a.user_id);
                                    if (check_assigned_user.length > 0) {
                                        u.uid.isChecked = true;
                                    }
                                    else {
                                        u.uid.isChecked = false;
                                    }
                                });
                            })
                        });

                        result.forEach(l => {
                            l.roles.forEach(r => {
                                var unchecked_users = r.users.filter(u => u.uid.isChecked == false);

                                if (unchecked_users.length > 0) {
                                    r.isChecked = false;
                                }
                                else {
                                    r.isChecked = true;
                                }
                            });
                        });

                        setLocationUsers(result);
                    }
                    else {
                        console.log(response.data);
                    }
                    setUsersLoading(false);
                    setUsersBarIsOpen(true);
                });
        }
    }, [api_selected_locations]);

    const onSectionOpenCloseDropdownClicked = (id) => {
        const index = sections.findIndex(s => s.id == id);

        setSections(s => {
            const newArr = s.slice();

            newArr[index].isClicked = !newArr[index].isClicked;

            return newArr;
        });
    }

    const onSubSectionOpenCloseDropdownClicked = (section_key, sub_section_key) => {
        setSections(s => {
            const newArr = s.slice();

            newArr[section_key].sub_sections[sub_section_key].isClicked = !newArr[section_key].sub_sections[sub_section_key].isClicked;

            // newArr[section_key].sub_sections.forEach((sub_section, key) => {
            //     if (key == sub_section_key) {
            //         sub_section.isClicked = !sub_section.isClicked;
            //     }
            // });

            return newArr;
        });
    }

    const handleQuestionBoxClicked = (section_id, sub_section_id, question_id) => {
        setUsersBarIsOpen(false);

        const index = sections.findIndex(s => s.id == section_id);

        var p_sections = Object.assign([], sections);

        p_sections[index].isClicked = true;
        p_sections.forEach(item => {
            item.sub_sections.forEach(subsection => {
                subsection.isClicked = false;
                subsection.questions.forEach(question => {
                    question.isClicked = false;
                });
            });
            item.sub_sections.forEach(subsection => {
                if (subsection.id == sub_section_id) {
                    subsection.isClicked = true;
                    subsection.questions.forEach(question => {
                        if (question.id == question_id && item.id == section_id) {
                            question.isClicked = true;
                        }
                    });
                }
            });
        });

        setIsQuestionSelected(true);
        setSelectedQuestion(null);
        setSubsectionDropdownIsOpen(true);
        setSections(p_sections);
        setIsQuestionSelected(true);
        let section = Object.assign({}, p_sections[index]);
        section.isClicked = false;
        // section.sub_sections.map(s => s.isClicked = false);
        // section.sub_sections[section.sub_sections.findIndex(s => s.id == sub_section_id)].isClicked = false;
        setSelectedSection(section);

        // old
        // setSections(s => {
        //     const newArr = s.slice();

        //     newArr.forEach(section => {
        //         section.sub_sections.forEach(subsection => {
        //             subsection.questions.forEach(question => {
        //                 question.isClicked = false;
        //             });
        //         });
        //     });

        //     newArr.forEach(section => {
        //         if (section.id == section_id) {
        //             section.sub_sections.forEach(subsection => {
        //                 if (subsection.id == sub_section_id) {
        //                     subsection.questions.forEach(question => {
        //                         if (question.id == question_id) {
        //                             question.isClicked = true;
        //                         }
        //                     });
        //                 }
        //             });
        //             setSelectedSection(section);
        //         }
        //     });

        //     return newArr;
        // });
    }

    const handleSectionBoxClicked = (e) => {
        e.preventDefault();

        setUsersBarIsOpen(false);

        const index = e.target.id;

        setSections(s => {
            const newArr = s.slice();

            newArr.forEach(item => {
                item.isClicked = false;
                item.sub_sections.forEach(subsection => {
                    subsection.questions.forEach(question => {
                        question.isClicked = false;
                    });
                });
            });
            newArr[index].isClicked = true;
            setSelectedSection(newArr[index]);
            setIsQuestionSelected(false);
            setSelectedQuestion(null);
            setSectionDropdownIsOpen(true);

            return newArr;
        });
    }

    const handleSubSectionBoxClicked = (section_key, sub_section_id) => {
        setUsersBarIsOpen(false);

        const index = section_key;

        var p_sections = Object.assign([], sections);

        p_sections[index].isClicked = true;
        p_sections.forEach(item => {
            item.sub_sections.forEach(subsection => {
                subsection.isClicked = false;
            });
            item.sub_sections.forEach(subsection => {
                if (subsection.id == sub_section_id) {
                    subsection.isClicked = true;
                }
                subsection.questions.forEach(question => {
                    question.isClicked = false;
                });
            });
        });

        setIsQuestionSelected(false);
        setSelectedQuestion(null);
        setSubsectionDropdownIsOpen(true);
        setSections(p_sections);
        let section = Object.assign({}, p_sections[index]);
        section.isClicked = false;
        setSelectedSection(section);
    }

    const handleQuestionEnabledSwitchChange = (checked, section_id, subsection_key, question_key) => {
        var section_index = sections.findIndex(s => s.id == section_id);

        setSections(s => {
            const newArr = s.slice();

            newArr[section_index].sub_sections[subsection_key].questions[question_key].answer.question.enabled = checked;

            return newArr;
        });

        // setQuestionnaireCards(s => {
        //     const newArr = s.slice();

        //     newArr.forEach(obj => {
        //         if (obj.isClicked) {
        //             obj.answer.question.enabled = checked;

        //             setSelectedQuestion(obj);
        //         }
        //     });

        //     return newArr;
        // });
    }

    const handleSectionEnabledSwitchChange = (checked) => {
        setSections(s => {
            const newArr = s.slice();

            newArr.forEach(obj => {
                if (obj.isClicked) {
                    obj.enabled = checked;

                    setSelectedSection(obj);
                }
            });

            return newArr;
        });
    }

    const handleSubSectionEnabledSwitchChange = (checked) => {
        setSections(s => {
            const newArr = s.slice();

            newArr.forEach(obj => {
                obj.sub_sections.forEach(s => {
                    if (s.isClicked) {
                        s.enabled = checked;
                    }
                });
            });

            return newArr;
        });
    }

    const handleEmailNotificationSwitchChange = (checked, section_id, subsection_key, question_key) => {
        var section_index = sections.findIndex(s => s.id == section_id);

        setSections(s => {
            const newArr = s.slice();

            newArr[section_index].sub_sections[subsection_key].questions[question_key].answer.email_notification = checked;

            return newArr;
        });
        // setQuestionnaireCards(s => {
        //     const newArr = s.slice();

        //     newArr.forEach(obj => {
        //         if (obj.isClicked) {
        //             obj.answer.email_notification = checked;

        //             setSelectedQuestion(obj);
        //         }
        //     });

        //     return newArr;
        // });
    }

    const handleActionPlanSwitchChange = (checked, section_id, subsection_key, question_key) => {
        var section_index = sections.findIndex(s => s.id == section_id);

        setSections(s => {
            const newArr = s.slice();

            newArr[section_index].sub_sections[subsection_key].questions[question_key].answer.action_plan = checked;

            return newArr;
        });
    }

    const handleQuestionChange = (e, section_id, sub_section_id, question_id) => {
        setSections(s => {
            const newArr = s.slice();

            newArr.forEach(section => {
                if (section.id == section_id) {
                    section.sub_sections.forEach(subsection => {
                        if (subsection.id == sub_section_id) {
                            subsection.questions.forEach(question => {
                                if (question.id == question_id) {
                                    question.value = e.target.value;
                                }
                            })
                        }
                    });
                }
            });

            return newArr;
        });
        // const index = e.target.id;
        // setQuestionnaireCards(s => {
        //     const newArr = s.slice();
        //     newArr[index].value = e.target.value;
        //     setSelectedQuestion(newArr[index]);

        //     return newArr;
        // });
    }

    const onBasicFieldClick = (type) => {
        if (selected_section) {
            const section_key = sections.findIndex(s => s.id == selected_section.id);

            setSections(s => {
                const newArr = s.slice();

                newArr[section_key].sub_sections.forEach(subsection => {
                    if (subsection.isClicked) {
                        subsection.questions.forEach(question => {
                            if (question.isClicked) {
                                let responses_deleted = Object.assign([], deleted_responses);

                                question.answer.responses?.forEach(response => {
                                    if (response._id) {
                                        responses_deleted = responses_deleted.concat(response._id);
                                    }
                                });

                                setDeletedResponses(responses_deleted);

                                question.answer = {
                                    type: type,
                                    question: {
                                        mandatory: false,
                                        enabled: true
                                    },
                                    responses: [{
                                        id: 1,
                                        value: "",
                                        bg_color: "",
                                        score: null,
                                        default: false,
                                        validation: {
                                            min: 0,
                                            max: 100
                                        },
                                        unit: "C"
                                    }],
                                    default_choice: "",
                                    question_help: "",
                                    picture: "",
                                    email_notification: false,
                                    action_plan: false,
                                    tags: []
                                };
                                // question.answer.type = type;
                            }
                        });
                    }
                });

                selected_section.sub_sections.forEach(subsection => {
                    if (subsection.isClicked) {
                        subsection.questions.forEach(question => {
                            if (question.isClicked) {
                                question.answer = {
                                    type: type,
                                    question: {
                                        mandatory: false,
                                        enabled: true
                                    },
                                    responses: [{
                                        id: 1,
                                        value: "",
                                        bg_color: "",
                                        score: null,
                                        default: false,
                                        validation: {
                                            min: 0,
                                            max: 100
                                        },
                                        unit: "C"
                                    }],
                                    default_choice: "",
                                    question_help: "",
                                    picture: "",
                                    email_notification: false,
                                    action_plan: false,
                                    tags: []
                                };
                                // question.answer.type = type;
                            }
                        });
                    }
                });

                return newArr;
            });
        }
        // setQuestionnaireCards(s => {
        //     const newArr = s.slice();

        //     newArr.forEach(obj => {
        //         if (obj.isClicked) {
        //             obj.answer.type = type;

        //             setSelectedQuestion(obj);
        //         }
        //     });

        //     return newArr;
        // });
    }

    const onLocationsFieldChanged = (selectedOption) => {
        setUsersLoading(true);
        let selected_options = [];
        selectedOption.forEach(option => {
            selected_options = selected_options.concat({
                id: option.value,
                name: option.label
            });
        });

        companyServices
            .fetchUsers({
                locations: selected_options
            })
            .then(response => {
                if (response.data.success) {
                    let result = response.data.result;
                    result.forEach(l => {
                        l.roles.forEach(r => {
                            r.isChecked = false;
                            r.users.forEach(u => {
                                u.uid.isChecked = false;
                            });

                            let previous_assigned_users = Object.assign([], location_users);

                            previous_assigned_users.forEach(p_l => {
                                p_l.roles.forEach(p_r => {
                                    if (l.location_id == p_l.location_id && r.role_id == p_r.role_id) {
                                        r.isChecked = p_r.isChecked;

                                        r.users.forEach(u => {
                                            u.uid.isChecked = false;

                                            p_r.users.forEach(p_u => {
                                                if (u.uid._id == p_u.uid._id) {
                                                    u.uid.isChecked = p_u.uid.isChecked;
                                                    return;
                                                }
                                            });
                                        });
                                    }
                                });
                            });
                        })
                    });

                    setLocationUsers(result);
                }
                else {
                    console.log(response.data);
                }
                setUsersLoading(false);
            });
        setSelectedLocations(selectedOption);
        setUsersBarIsOpen(true);
    }

    const onInputFieldChanged = (e, section_id = null, sub_section_index = null, question_index = null) => {
        var section_index = sections.findIndex(s => s.id == section_id);

        if (e.target.name == "section_name") {
            setSections(s => {
                const newArr = s.slice();

                newArr.forEach(obj => {
                    if (obj.isClicked) {
                        obj.name = e.target.value;

                        setSelectedSection(obj);
                    }
                });

                return newArr;
            });
        }
        else if (e.target.name == "section_help") {
            setSections(s => {
                const newArr = s.slice();

                newArr.forEach(obj => {
                    if (obj.isClicked) {
                        obj.help = e.target.value;

                        setSelectedSection(obj);
                    }
                });

                return newArr;
            });
        }
        else if (e.target.name == "sub_section_name") {
            setSections(s => {
                const newArr = s.slice();

                newArr.forEach(obj => {
                    obj.sub_sections.forEach(subsection => {
                        if (subsection.isClicked == true) {
                            subsection.name = e.target.value;
                        }
                    });
                });

                return newArr;
            });
        }
        else if (e.target.name == "template_name") {
            setTemplateName(e.target.value);
        }
        else if (e.target.name == "location") {
            setLocation(e.target.value);
        }
        else if (e.target.name == "frequency") {
            setFrequency(e.target.value);
        }
        else if (e.target.name == "start_date") {
            setStartDate(e.target.value);
        }
        else if (e.target.name == "end_date") {
            setEndDate(e.target.value);
        }
        else if (e.target.name == "question_mandatory_checkbox") {
            setSections(s => {
                const newArr = s.slice();

                newArr[section_index].sub_sections[sub_section_index].questions[question_index].answer.question.mandatory = e.target.checked;

                return newArr;
            });
        }
        else if (e.target.name == "default_choice") {
            setSections(s => {
                const newArr = s.slice();

                newArr[section_index].sub_sections[sub_section_index].questions[question_index].answer.responses.map(response => {
                    response.default = false;
                    if (response.value == e.target.value) {
                        response.default = true;
                    }
                });

                newArr[section_index].sub_sections[sub_section_index].questions[question_index].answer.default_choice = e.target.value;

                return newArr;
            });
            // setQuestionnaireCards(s => {
            //     const newArr = s.slice();

            //     newArr.forEach(obj => {
            //         if (obj.isClicked) {
            //             obj.answer.responses.map(response => {
            //                 response.default = false;
            //                 if (response.value == e.target.value) {
            //                     response.default = true;
            //                 }
            //             });
            //             obj.answer.default_choice = e.target.value;

            //             setSelectedQuestion(obj);
            //         }
            //     });

            //     return newArr;
            // });
        }
        else if (e.target.name == "question_help") {
            setSections(s => {
                const newArr = s.slice();

                newArr[section_index].sub_sections[sub_section_index].questions[question_index].answer.question_help = e.target.value;

                return newArr;
            });
            // setQuestionnaireCards(s => {
            //     const newArr = s.slice();

            //     newArr.forEach(obj => {
            //         if (obj.isClicked) {
            //             obj.answer.question_help = e.target.value;

            //             setSelectedQuestion(obj);
            //         }
            //     });

            //     return newArr;
            // });
        }
        else if (e.target.name == "min") {
            setSections(s => {
                const newArr = s.slice();

                newArr[section_index].sub_sections[sub_section_index].questions[question_index].answer.responses[0].validation.min = e.target.value;

                return newArr;
            });
            // setQuestionnaireCards(s => {
            //     const newArr = s.slice();

            //     newArr.forEach(obj => {
            //         if (obj.isClicked) {
            //             obj.answer.responses[0].validation.min = e.target.value;

            //             setSelectedQuestion(obj);
            //         }
            //     });

            //     return newArr;
            // });
        }
        else if (e.target.name == "max") {
            setSections(s => {
                const newArr = s.slice();

                newArr[section_index].sub_sections[sub_section_index].questions[question_index].answer.responses[0].validation.max = e.target.value;

                return newArr;
            });
            // setQuestionnaireCards(s => {
            //     const newArr = s.slice();

            //     newArr.forEach(obj => {
            //         if (obj.isClicked) {
            //             obj.answer.responses[0].validation.max = e.target.value;

            //             setSelectedQuestion(obj);
            //         }
            //     });

            //     return newArr;
            // });
        }
        else if (e.target.name == "unit") {
            setSections(s => {
                const newArr = s.slice();

                newArr[section_index].sub_sections[sub_section_index].questions[question_index].answer.responses[0].unit = e.target.value;

                return newArr;
            });
            // setQuestionnaireCards(s => {
            //     const newArr = s.slice();

            //     newArr.forEach(obj => {
            //         if (obj.isClicked) {
            //             obj.answer.responses[0].unit = e.target.value;

            //             setSelectedQuestion(obj);
            //         }
            //     });

            //     return newArr;
            // });
        }
        else if (e.target.name == "task_start_date") {
            setStartDate(e.target.value);
        }
        else if (e.target.name == "task_end_date") {
            setEndDate(e.target.value);
        }
        // else if (e.target.name == "question_start_date") {
        //     setQuestionnaireCards(s => {
        //         const newArr = s.slice();

        //         newArr.forEach(obj => {
        //             if (obj.isClicked) {
        //                 obj.start_date = e.target.value;

        //                 setSelectedQuestion(obj);
        //             }
        //         });

        //         return newArr;
        //     });
        // }
        // else if (e.target.name == "question_end_date") {
        //     setQuestionnaireCards(s => {
        //         const newArr = s.slice();

        //         newArr.forEach(obj => {
        //             if (obj.isClicked) {
        //                 obj.end_date = e.target.value;

        //                 setSelectedQuestion(obj);
        //             }
        //         });

        //         return newArr;
        //     });
        // }
    }

    const onResponseInputFieldChanged = (e, response_key, section_id, subsection_key, question_key) => {
        var section_index = sections.findIndex(s => s.id == section_id);

        setSections(s => {
            const newArr = s.slice();

            newArr[section_index].sub_sections[subsection_key].questions[question_key].answer.responses[response_key].value = e.target.value;

            return newArr;
        });

        // setQuestionnaireCards(s => {
        //     const newArr = s.slice();

        //     newArr.forEach(obj => {
        //         if (obj.isClicked) {
        //             obj.answer.responses.forEach(response => {
        //                 if (response.id == response_id) {
        //                     response.value = e.target.value;
        //                 }
        //             });

        //             setSelectedQuestion(obj);
        //         }
        //     });

        //     return newArr;
        // });
    }

    const onResponseScoreInputFieldChanged = (e, response_key, section_id, subsection_key, question_key) => {
        var section_index = sections.findIndex(s => s.id == section_id);

        setSections(s => {
            const newArr = s.slice();

            newArr[section_index].sub_sections[subsection_key].questions[question_key].answer.responses[response_key].score = e.target.value;

            return newArr;
        });
        // setQuestionnaireCards(s => {
        //     const newArr = s.slice();

        //     newArr.forEach(obj => {
        //         if (obj.isClicked) {
        //             obj.answer.responses.forEach(response => {
        //                 if (response.id == response_id) {
        //                     response.score = e.target.value;
        //                 }
        //             });

        //             setSelectedQuestion(obj);
        //         }
        //     });

        //     return newArr;
        // });
    }

    // const addQuestionInput = () => {
    //     setQuestionnaireCards(s => {
    //         const newArr = s.slice();

    //         newArr.forEach(item => {
    //             item.isClicked = false;
    //         });

    //         setSelectedQuestion(null);

    //         return newArr;
    //     });

    //     setQuestionnaireCards(s => {
    //         return [
    //             ...s,
    //             {
    //                 id: questionnaire_cards.length + 1,
    //                 value: "",
    //                 isClicked: true,
    //                 answer: {
    //                     type: "",
    //                     question: {
    //                         mandatory: false,
    //                         enabled: true
    //                     },
    //                     responses: [{
    //                         id: 1,
    //                         value: "",
    //                         bg_color: "",
    //                         score: null,
    //                         default: false,
    //                         validation: {
    //                             min: 0,
    //                             max: 100
    //                         },
    //                         unit: "C"
    //                     }],
    //                     default_choice: "",
    //                     question_help: "",
    //                     picture: "",
    //                     email_notification: false,
    //                     action_plan: false,
    //                     tags: []
    //                 },
    //                 start_date: "",
    //                 end_date: ""
    //             }
    //         ];
    //     });
    // };

    const onDeleteQuestionClicked = (question, section_key, subsection_key) => {
        if (question._id) {
            let questions_deleted = Object.assign([], deleted_tasks);
            questions_deleted = questions_deleted.concat(question._id);
            setDeletedTasks(questions_deleted);

            var question_index = sections[section_key].sub_sections[subsection_key].questions.findIndex(q => q.id == question.id);

            let responses_deleted = Object.assign([], deleted_responses);

            sections[section_key].sub_sections[subsection_key].questions[question_index].answer.responses.forEach(r => {
                if (r._id) {
                    responses_deleted = responses_deleted.concat(r._id);
                }
            });

            setDeletedResponses(responses_deleted);
        }

        setSections(s => {
            const newArr = s.slice();

            newArr[section_key].sub_sections[subsection_key].questions = newArr[section_key].sub_sections[subsection_key].questions.filter(q => q.id != question.id);

            return newArr;
        });
        setIsQuestionSelected(false);
        setSelectedQuestion(null);
    }

    const addSectionBtnClicked = () => {
        setSections(s => {
            var newArr = s.slice();

            newArr.forEach(section => {
                section.sub_sections.forEach(subsection => {
                    subsection.questions.forEach(question => {
                        question.isClicked = false;
                    })
                })
            })

            newArr = newArr.concat({
                id: newArr.length + 1,
                name: "",
                enabled: true,
                help: "",
                isClicked: true,
                sub_sections: [
                    {
                        id: 1,
                        name: "",
                        enabled: true,
                        isClicked: true,
                        questions: [
                            {
                                id: 1,
                                value: "",
                                isClicked: true,
                                answer: {
                                    type: "button",
                                    question: {
                                        mandatory: false,
                                        enabled: true
                                    },
                                    responses: [{
                                        id: 1,
                                        value: "",
                                        bg_color: "",
                                        score: null,
                                        default: false,
                                        validation: {
                                            min: 0,
                                            max: 100
                                        },
                                        unit: "C"
                                    }],
                                    default_choice: "",
                                    question_help: "",
                                    picture: "",
                                    email_notification: false,
                                    action_plan: false,
                                    tags: []
                                }
                            }
                        ]
                    }
                ]
            });

            return newArr;
        })
        // setSections(s => {
        //     return [
        //         ...s,
        //         {
        //             id: sections.length + 1,
        //             name: "",
        //             enabled: true,
        //             help: "",
        //             isClicked: true,
        //             sub_sections: [
        //                 {
        //                     id: 1,
        //                     name: "",
        //                     enabled: true,
        //                     isClicked: true,
        //                     questions: [
        //                         {
        //                             id: 1,
        //                             value: "",
        //                             isClicked: true,
        //                             answer: {
        //                                 type: "button",
        //                                 question: {
        //                                     mandatory: false,
        //                                     enabled: true
        //                                 },
        //                                 responses: [{
        //                                     id: 1,
        //                                     value: "",
        //                                     bg_color: "",
        //                                     score: null,
        //                                     default: false,
        //                                     validation: {
        //                                         min: 0,
        //                                         max: 100
        //                                     },
        //                                     unit: "C"
        //                                 }],
        //                                 default_choice: "",
        //                                 question_help: "",
        //                                 picture: "",
        //                                 email_notification: false,
        //                                 action_plan: false,
        //                                 tags: []
        //                             }
        //                         }
        //                     ]
        //                 }
        //             ]
        //         }
        //     ]
        // });
        setSelectedSection(null);
    }

    const onDeleteSectionClicked = (sec) => {
        let filtered_sections = sections.filter(section => section.id != sec.id);
        setSections(filtered_sections);
        setIsQuestionSelected(false);
        setSelectedQuestion(null);
        setSelectedSection(null);
        if (sec._id) {
            let section_deleted = Object.assign([], deleted_sections);
            section_deleted = section_deleted.concat(sec._id);
            setDeletedSections(section_deleted);

            var section_index = sections.findIndex(s => s._id == sec._id);

            let subsections_deleted = Object.assign([], deleted_sub_sections);
            let tasks_deleted = Object.assign([], deleted_tasks);
            let responses_deleted = Object.assign([], deleted_responses);

            sections[section_index].sub_sections.forEach(subsection => {
                if (subsection._id) {
                    subsections_deleted = subsections_deleted.concat(subsection._id);

                    subsection.questions.forEach(q => {
                        if (q._id) {
                            tasks_deleted = tasks_deleted.concat(q._id);

                            q.answer.responses.forEach(r => {
                                if (r._id) {
                                    responses_deleted = responses_deleted.concat(r._id);
                                }
                            });
                        }
                    });
                }
            });

            setDeletedSubSections(subsections_deleted);
            setDeletedTasks(tasks_deleted);
            setDeletedResponses(responses_deleted);
        }
    }

    const onDeleteSubSectionClicked = (section_id, sub_section) => {
        if (sub_section._id) {
            var section_key = sections.findIndex(s => s.id == section_id);
            let subsections_deleted = Object.assign([], deleted_sub_sections);
            subsections_deleted = subsections_deleted.concat(sub_section._id);
            setDeletedSubSections(subsections_deleted);

            var sub_section_index = sections[section_key].sub_sections.findIndex(s => s._id == sub_section._id);

            let tasks_deleted = Object.assign([], deleted_tasks);
            let responses_deleted = Object.assign([], deleted_responses);

            sections[section_key].sub_sections[sub_section_index].questions.forEach(q => {
                if (q._id) {
                    tasks_deleted = tasks_deleted.concat(q._id);

                    q.answer.responses.forEach(r => {
                        if (r._id) {
                            responses_deleted = responses_deleted.concat(r._id);
                        }
                    });
                }
            });

            setDeletedTasks(tasks_deleted);
            setDeletedResponses(responses_deleted);
        }

        var p_sections = Object.assign([], sections);
        p_sections.forEach((section, key) => {
            if (section.id == section_id) {
                section.sub_sections = section.sub_sections.filter(s => s.id != sub_section.id);
            }
        });
        setSections(p_sections);
        setIsQuestionSelected(false);
        setSelectedQuestion(null);
        setSelectedSection(null);
    }

    const addSubSectionBtnClicked = (section_key) => {
        setSections(s => {
            const newArr = s.slice();

            newArr[section_key].sub_sections.forEach(subsection => {
                subsection.questions.forEach(question => {
                    question.isClicked = false;
                });
            });

            newArr[section_key].sub_sections = newArr[section_key].sub_sections.concat({
                id: newArr[section_key].sub_sections.length + 1,
                name: "",
                enabled: true,
                isClicked: true,
                questions: [
                    {
                        id: 1,
                        value: "",
                        isClicked: true,
                        answer: {
                            type: "button",
                            question: {
                                mandatory: false,
                                enabled: true
                            },
                            responses: [{
                                id: 1,
                                value: "",
                                bg_color: "",
                                score: null,
                                default: false,
                                validation: {
                                    min: 0,
                                    max: 100
                                },
                                unit: "C"
                            }],
                            default_choice: "",
                            question_help: "",
                            picture: "",
                            email_notification: false,
                            action_plan: false,
                            tags: []
                        }
                    }
                ]
            });

            return newArr;
        });
    }

    const addTaskBtnClicked = (section_key, sub_section_key) => {
        setSections(s => {
            const newArr = s.slice();

            newArr.forEach(section => {
                section.sub_sections.forEach(subsection => {
                    subsection.questions.forEach(question => {
                        question.isClicked = false;
                    });
                });
            });

            newArr[section_key].sub_sections[sub_section_key].questions = newArr[section_key].sub_sections[sub_section_key].questions.concat({
                id: newArr[section_key].sub_sections[sub_section_key].questions.length + 1,
                value: "",
                isClicked: true,
                answer: {
                    type: "button",
                    question: {
                        mandatory: false,
                        enabled: true
                    },
                    responses: [{
                        id: 1,
                        value: "",
                        bg_color: "",
                        score: null,
                        default: false,
                        validation: {
                            min: 0,
                            max: 100
                        },
                        unit: "C"
                    }],
                    default_choice: "",
                    question_help: "",
                    picture: "",
                    email_notification: false,
                    action_plan: false,
                    tags: []
                }
            });

            return newArr;
        });
    }

    const addResponse = (section_id, subsection_key, question_key) => {
        var section_index = sections.findIndex(s => s.id == section_id);

        setSections(s => {
            const newArr = s.slice();

            newArr[section_index].sub_sections[subsection_key].questions[question_key].answer.responses = newArr[section_index].sub_sections[subsection_key].questions[question_key].answer.responses.concat({
                id: newArr[section_index].sub_sections[subsection_key].questions[question_key].answer.responses.length + 1,
                value: "",
                bg_color: "",
                score: null,
                default: false,
                validation: {
                    min: 0,
                    max: 100
                },
                unit: "C"
            });

            return newArr;
        });
        // let questions = Object.assign([], questionnaire_cards);

        // questions.forEach((question_card, key) => {
        //     if (question_card.isClicked) {
        //         question_card.answer.responses = question_card.answer.responses.concat({
        //             id: question_card.answer.responses.length + 1,
        //             value: "",
        //             bg_color: "",
        //             score: null,
        //             default: false,
        //             validation: {
        //                 min: 0,
        //                 max: 100
        //             },
        //             unit: "C"
        //         });
        //         setSelectedQuestion(question_card);
        //     }
        // });

        // setQuestionnaireCards(questions);
    }

    const onDeleteResponseClicked = (response, section_id, subsection_key, question_key) => {
        if (response._id) {
            let responses_deleted = Object.assign([], deleted_responses);
            responses_deleted = responses_deleted.concat(response._id);
            setDeletedResponses(responses_deleted);
        }

        var section_key = sections.findIndex(s => s.id == section_id);

        setSections(s => {
            const newArr = s.slice();

            newArr[section_key].sub_sections[subsection_key].questions[question_key].answer.responses = newArr[section_key].sub_sections[subsection_key].questions[question_key].answer.responses.filter(resp => resp.id != response.id);

            return newArr;
        });
        // let questions = Object.assign([], questionnaire_cards);

        // questions.forEach((question_card, key) => {
        //     if (question_card.isClicked) {
        //         question_card.answer.responses = question_card.answer.responses.filter(r => r.id != response_id);

        //         setSelectedQuestion(question_card);
        //     }
        // });

        // setQuestionnaireCards(questions);
    }

    const onLocationClicked = (location_index) => {
        setLocationUsers(l => {
            const newArr = l.slice();

            newArr[location_index].isClicked = !newArr[location_index].isClicked;

            return newArr;
        })
    }

    const onRoleClicked = (location_index, role_index) => {
        setLocationUsers(l => {
            const newArr = l.slice();

            newArr[location_index].roles[role_index].isClicked = !newArr[location_index].roles[role_index].isClicked;

            return newArr;
        });
    }

    const onRoleCheckboxClicked = (location_index, role_index) => {
        setLocationUsers(l => {
            const newArr = l.slice();

            newArr[location_index].roles[role_index].isChecked = !newArr[location_index].roles[role_index].isChecked;

            if (newArr[location_index].roles[role_index].isChecked) {
                newArr[location_index].roles[role_index].users.forEach(user => {
                    user.uid.isChecked = true;
                });
            }
            else {
                newArr[location_index].roles[role_index].users.forEach(user => {
                    user.uid.isChecked = false;
                });
            }

            return newArr;
        });
    }

    const onUserCheckboxClicked = (location_index, role_index, user_index) => {
        setLocationUsers(l => {
            const newArr = l.slice();

            newArr[location_index].roles[role_index].users[user_index].uid.isChecked = !newArr[location_index].roles[role_index].users[user_index].uid.isChecked;

            var unchecked_users = newArr[location_index].roles[role_index].users.filter(u => u.uid.isChecked == false);

            if (unchecked_users.length > 0) {
                newArr[location_index].roles[role_index].isChecked = false;
            }
            else {
                newArr[location_index].roles[role_index].isChecked = true;
            }

            return newArr;
        });
    }

    const onUploadPhotoClicked = (task_id) => {
        document.getElementById("help_img_" + task_id).click()
    }

    const fileChangedHandler = (event, section_id, subsection_index, task_index) => {
        var section_index = sections.findIndex(s => s.id == section_id);

        var imageFile = event.target.files[0];
        if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/)) {
            let p_sections = Object.assign([], sections);

            p_sections[section_index].sub_sections[subsection_index].questions[task_index].help_image = null;

            let p_selected_section = Object.assign({}, selected_section);
            p_selected_section.sub_sections[subsection_index].questions[task_index].help_image_error = "*Please select valid image.";
            p_selected_section.sub_sections[subsection_index].questions[task_index].help_image = null;

            setSelectedSection(p_selected_section);

            setSections(p_sections);

            return;
        }
        else {
            let p_sections = Object.assign([], sections);

            p_sections[section_index].sub_sections[subsection_index].questions[task_index].help_image_error = "";

            companyServices
                .saveImage(imageFile)
                .then(response => {
                    if (response.data.success) {
                        p_sections[section_index].sub_sections[subsection_index].questions[task_index].help_image = response.data.result;

                        let p_selected_section = Object.assign({}, selected_section);
                        p_selected_section.sub_sections[subsection_index].questions[task_index].help_image_error = "";
                        p_selected_section.sub_sections[subsection_index].questions[task_index].help_image = response.data.result;

                        setSelectedSection(p_selected_section);
                    }

                    setSections(p_sections);
                });
        }
    }

    const onHelpImageHover = (section_id, subsection_index, task_index) => {
        let p_selected_section = Object.assign({}, selected_section);

        p_selected_section.sub_sections[subsection_index].questions[task_index].help_image_hover = true;

        setSelectedSection(p_selected_section);
    }

    const onHelpImageUnHover = (subsection_index, task_index) => {
        let p_selected_section = Object.assign({}, selected_section);

        p_selected_section.sub_sections[subsection_index].questions[task_index].help_image_hover = false;

        setSelectedSection(p_selected_section);
    }

    const onDeleteHelpImageClicked = (section_id, subsection_index, task_index) => {
        var section_index = sections.findIndex(s => s.id == section_id);

        let p_sections = Object.assign([], sections);
        p_sections[section_index].sub_sections[subsection_index].questions[task_index].help_image = null;
        setSections(p_sections);

        let p_selected_section = Object.assign({}, selected_section);
        p_selected_section.sub_sections[subsection_index].questions[task_index].help_image_error = "";
        p_selected_section.sub_sections[subsection_index].questions[task_index].help_image = null;

        setSelectedSection(p_selected_section);
    }

    function getDailyDatesInRange(startDate, endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);

        const date = new Date(start.getTime());

        const dates = [];

        while (date <= end) {
            dates.push(moment(date).format('YYYY-MM-DDTHH:mm'));
            date.setDate(date.getDate() + 1);
        }

        return dates;
    }

    function getWeeklyDatesInRange(startDate, endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);

        const date = new Date(start.getTime());

        const dates = [];

        while (date <= end) {
            dates.push(moment(date).format('YYYY-MM-DDTHH:mm'));
            date.setDate(date.getDate() + 7);
        }

        return dates;
    }

    function getMonthlyDatesInRange(startDate, endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);

        const date = new Date(start.getTime());

        const dates = [];

        while (date <= end) {
            dates.push(moment(date).format('YYYY-MM-DDTHH:mm'));
            date.setMonth(date.getMonth() + 1);
        }

        return dates;
    }

    const handleValidation = () => {
        var isValid = true;

        if (!template_name) {
            isValid = false;
            setTemplateNameError("*Template name is required");
        }
        else {
            setTemplateNameError("");
        }

        if (!frequency) {
            isValid = false;
            setFrequencyError("*Frequency is required");
        }
        else {
            setFrequencyError("");
        }

        if (!start_date) {
            isValid = false;
            setStartDateError("*Start date is required");
        }
        else {
            setStartDateError("");
        }

        if (!end_date) {
            isValid = false;
            setEndDateError("*End date is required");
        }
        else {
            setEndDateError("");
        }

        if (selected_locations.length <= 0) {
            isValid = false;
            setLocationError("*Atleast one location is required");
        }
        else {
            setLocationError("");
        }

        var empty_sections = sections.filter(section => section.name == "");

        if (empty_sections.length > 0) {
            isValid = false;
            setTasksError("Section names are required.");
        }
        else {
            setTasksError("");

            var empty_subsections = 0;
            sections.forEach(section => {
                section.sub_sections.forEach(subsection => {
                    if (subsection.name == "") {
                        empty_subsections++;
                        return;
                    }
                });
            });

            if (empty_subsections > 0) {
                isValid = false;
                setTasksError("Sub-Section names are required.");
            }
            else {
                setTasksError("");

                var empty_tasks = 0;
                sections.forEach(section => {
                    section.sub_sections.forEach(subsection => {
                        subsection.questions.forEach(question => {
                            if (question.value == "") {
                                empty_tasks++;
                                return;
                            }
                        });
                    });
                });

                if (empty_tasks > 0) {
                    isValid = false;
                    setTasksError("*All tasks are required.");
                }
                else {
                    setTasksError("");

                    var empty_responses = 0;
                    sections.forEach(section => {
                        section.sub_sections.forEach(subsection => {
                            subsection.questions.forEach(question => {
                                question.answer.responses.forEach(response => {
                                    if (
                                        (response.value == "" && question.answer.type == "button")
                                        ||
                                        (response.value == "" && question.answer.type == "list")
                                        ||
                                        (response.value == "" && question.answer.type == "radio")
                                        ||
                                        (response.value == "" && question.answer.type == "checkbox")
                                    ) {
                                        empty_responses++;
                                        return;
                                    }
                                });
                            });
                        });
                    });

                    if (empty_responses > 0) {
                        isValid = false;
                        setTasksError("*All responses are required.");
                    }
                    else {
                        setTasksError("");

                        var is_user_selected = 0;

                        location_users.forEach(l => {
                            l.roles.forEach(r => {
                                r.users.forEach(u => {
                                    if (u.uid.isChecked) {
                                        is_user_selected = 1;
                                        return;
                                    }
                                });
                            });
                        });

                        if (is_user_selected == 0) {
                            isValid = false;
                            setTasksError("*Select atleast 1 user to proceed.");
                        }
                        else {
                            setTasksError("");
                        }
                    }
                }
            }
        }

        return isValid;
    }

    const onSaveBtnClicked = () => {
        if (handleValidation()) {
            let assigned_forms = [];
            location_users.forEach(l => {
                l.roles.forEach(r => {
                    r.users.forEach(u => {
                        if (u.uid.isChecked) {
                            assigned_forms = assigned_forms.concat({
                                location_id: l.location_id,
                                user_id: u.uid._id
                            });
                        }
                    });
                });
            });

            let form_frequencies = [];

            if (frequency == "daily") {
                form_frequencies = getDailyDatesInRange(start_date, end_date);
            }
            else if (frequency == "weekly") {
                form_frequencies = getWeeklyDatesInRange(start_date, end_date);
            }
            else if (frequency == "monthly") {
                form_frequencies = getMonthlyDatesInRange(start_date, end_date);
            }

            var form = {
                form: {
                    template_name: template_name,
                    frequency: frequency,
                    locations: selected_locations.map(location => location.value),
                    start_date: start_date,
                    end_date: end_date,
                    sections: sections
                },
                assigned_users: assigned_forms,
                form_frequencies: form_frequencies,
                deleted_sections: deleted_sections,
                deleted_sub_sections: deleted_sub_sections,
                deleted_tasks: deleted_tasks,
                deleted_responses: deleted_responses
            };

            setLoading(true);
            QuessionaireApi
                .editForm(props.match.params.id, form)
                .then(response => {
                    if (response.success) {
                        alert.success(response.message);
                    }
                    else {
                        alert.error("Error. Try again.");
                    }
                    setLoading(false);
                });
        }
    }

    return (
        <div className="questionnaire">
            <ul class="breadcrumb breadcrumb-style">
                <li _ngcontent-xyx-c297="" class="breadcrumb-item bcrumb-1">
                    <Link to="">Templates</Link>
                </li>
                <li _ngcontent-xyx-c297="" class="breadcrumb-item bcrumb-2">
                    <Link to="">Form Builder</Link>
                </li>
                <li _ngcontent-xyx-c297="" class="breadcrumb-item active">List</li>
            </ul>
            <h2 className="page-title">Edit Form</h2>
            {
                loading ?
                    <div className="text-center">
                        <div class="spinner-grow text-secondary mt-5" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    :
                    <>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-lg-2 col-md-3 col-sm-6 my-1">
                                        <label className="mb-0 font-weight-bold top-label">Form Name</label>
                                        <input
                                            name="template_name"
                                            type="text"
                                            onChange={onInputFieldChanged}
                                            value={template_name}
                                            onFocus={() => {
                                                setSelectedSection(null);
                                                setUsersBarIsOpen(false);
                                            }}
                                            className="form-control"
                                            placeholder="Template Name" />
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-sm-6 my-1">
                                        <label className="mb-0 font-weight-bold top-label">Frequency</label>
                                        <select
                                            name="frequency"
                                            onChange={onInputFieldChanged}
                                            value={frequency}
                                            onFocus={() => {
                                                setSelectedSection(null);
                                                setUsersBarIsOpen(false);
                                            }}
                                            className="form-control">
                                            <option value="">--- Select Frequency ---</option>
                                            <option value="daily">Daily</option>
                                            <option value="weekly">Weekly</option>
                                            <option value="monthly">Monthly</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-sm-6 my-1">
                                        <label className="mb-0 font-weight-bold top-label">Start date</label>
                                        <input
                                            name="start_date"
                                            type="datetime-local"
                                            value={start_date}
                                            onChange={onInputFieldChanged}
                                            onFocus={() => {
                                                setSelectedSection(null);
                                                setUsersBarIsOpen(false);
                                            }}
                                            className="form-control" />
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-sm-6 my-1">
                                        <label className="mb-0 font-weight-bold top-label">End date</label>
                                        <input
                                            name="end_date"
                                            type="datetime-local"
                                            value={end_date}
                                            onChange={onInputFieldChanged}
                                            onFocus={() => {
                                                setSelectedSection(null);
                                                setUsersBarIsOpen(false);
                                            }}
                                            className="form-control" />
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-sm-6 my-1">
                                        <label className="mb-0 font-weight-bold top-label">Location</label>
                                        <RSelect
                                            options={locations}
                                            isMulti={true}
                                            onChange={onLocationsFieldChanged}
                                            value={selected_locations}
                                            onFocus={() => setSelectedSection(null)}
                                        />
                                        {/* <select name="location" onChange={onInputFieldChanged} value={location} className="form-control">
                                    <option value="">--- Select Location ---</option>
                                    {
                                        props.locationData.map(location => {
                                            return (
                                                <option value={location._id}>{location.name}</option>
                                            )
                                        })
                                    }
                                </select> */}
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-sm-6 d-flex align-items-end my-1">
                                        <button 
                                        className="btn btn-block btn-save text-center bg-theme text-white font-weight-700" 
                                        onClick={onSaveBtnClicked}
                                        style={{
                                            backgroundColor: ovRoutineColor.main
                                        }}
                                        >Save</button>
                                    </div>
                                </div>
                                <div className="row mx-0">
                                    <p className="text text-danger mb-0">
                                        {
                                            template_name_error ?
                                                template_name_error
                                                :
                                                frequency_error ?
                                                    frequency_error
                                                    :
                                                    start_date_error ?
                                                        start_date_error
                                                        :
                                                        end_date_error ?
                                                            end_date_error
                                                            :
                                                            location_error ?
                                                                location_error
                                                                :
                                                                tasks_error ?
                                                                    tasks_error
                                                                    :
                                                                    <></>
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-lg-3 mt-1 mb-1">
                                <div className="card">
                                    <div className="card-body">
                                        <div
                                            onClick={() => setBasicFieldsDropdownIsOpen(!basicFieldsDropdownIsOpen)}
                                            className="row mx-0 left-card-header">
                                            Basic Fields
                                            {
                                                basicFieldsDropdownIsOpen ?
                                                    <IoIosArrowDown size={18} className="arrow-icon" />
                                                    :
                                                    <IoIosArrowForward size={18} className="arrow-icon" />
                                            }
                                        </div>
                                        {
                                            basicFieldsDropdownIsOpen ?
                                                <div className="basic-fields">
                                                    <div className="row mx-0">
                                                        <div className="col-lg-6 basic-field-col">
                                                            <div className="row mx-0 basic-field-box" onClick={() => onBasicFieldClick("button")}>
                                                                <img src={ButtonIcon} />
                                                                <p className="mb-0">&nbsp;Button</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 basic-field-col">
                                                            <div className="row mx-0 basic-field-box" onClick={() => onBasicFieldClick("text")}>
                                                                <img src={TextIcon} />
                                                                <p className="mb-0">&nbsp;Text</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 basic-field-col">
                                                            <div className="row mx-0 basic-field-box" onClick={() => onBasicFieldClick("number")}>
                                                                <img src={NumberIcon} />
                                                                <p className="mb-0">&nbsp;Number</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 basic-field-col">
                                                            <div className="row mx-0 basic-field-box" onClick={() => onBasicFieldClick("date")}>
                                                                <img src={DateIcon} />
                                                                <p className="mb-0">&nbsp;Date</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 basic-field-col">
                                                            <div className="row mx-0 basic-field-box" onClick={() => onBasicFieldClick("list")}>
                                                                <img src={ListIcon} />
                                                                <p className="mb-0">&nbsp;List</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 basic-field-col">
                                                            <div className="row mx-0 basic-field-box" onClick={() => onBasicFieldClick("datetime")}>
                                                                <img src={DateTimeIcon} />
                                                                <p className="mb-0">&nbsp;DateTime</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 basic-field-col">
                                                            <div className="row mx-0 basic-field-box" onClick={() => onBasicFieldClick("radio")}>
                                                                <img src={RadioIcon} />
                                                                <p className="mb-0">&nbsp;Radio</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 basic-field-col">
                                                            <div className="row mx-0 basic-field-box" onClick={() => onBasicFieldClick("temperature")}>
                                                                <img src={TemperatureIcon} />
                                                                <p className="mb-0">&nbsp;Temperature</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 basic-field-col">
                                                            <div className="row mx-0 basic-field-box" onClick={() => onBasicFieldClick("checkbox")}>
                                                                <img src={CheckboxIcon} />
                                                                <p className="mb-0">&nbsp;Checkbox</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 basic-field-col">
                                                            <div className="row mx-0 basic-field-box" onClick={() => onBasicFieldClick("image")}>
                                                                <img src={ImageIcon} />
                                                                <p className="mb-0">&nbsp;Image</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                        }
                                        {/* <div
                                    onClick={() => setYourResponsesDropdownIsOpen(!yourResponsesDropdownIsOpen)}
                                    className="row mx-0 left-card-header top-border">
                                    Your responses
                                    {
                                        yourResponsesDropdownIsOpen ?
                                            <IoIosArrowDown size={18} className="arrow-icon" />
                                            :
                                            <IoIosArrowForward size={18} className="arrow-icon" />
                                    }
                                </div>
                                {
                                    yourResponsesDropdownIsOpen ?
                                        <div className="responses">
                                            <div className="section">
                                                <span className="button" style={{ color: "rgb(26, 188, 156)", backgroundColor: "rgba(26, 188, 156, 0.18)" }}>Yes</span>
                                                <span className="button" style={{ color: "rgb(210, 77, 87)", backgroundColor: "rgba(210, 77, 87, 0.18)" }}>No</span>
                                                <span className="button" style={{ color: "rgb(219, 176, 79)", backgroundColor: "rgba(219, 176, 79, 0.18)" }}>N/A</span>
                                                <span className="button" style={{ color: "rgb(210, 77, 87)", backgroundColor: "rgba(210, 77, 87, 0.18)" }}>10</span>
                                                <span className="button" style={{ color: "rgb(188, 152, 126)", backgroundColor: "rgba(188, 152, 126, 0.18)" }}>20</span>
                                                <span className="button" style={{ color: "rgb(219, 176, 79)", backgroundColor: "rgba(219, 176, 79, 0.18)" }}>30</span>
                                                <span className="button" style={{ color: "rgb(137, 207, 240)", backgroundColor: "rgba(137, 207, 240, 0.18)" }}>40</span>
                                                <span className="button" style={{ color: "rgb(0, 187, 87)", backgroundColor: "rgba(0, 187, 87, 0.18)" }}>50</span>
                                            </div>
                                            <hr className="mt-0 mb-0" />
                                            <div className="section">
                                                <span className="button" style={{ color: "rgb(26, 188, 156)", backgroundColor: "rgba(26, 188, 156, 0.18)" }}>Yes</span>
                                                <span className="button" style={{ color: "rgb(210, 77, 87)", backgroundColor: "rgba(210, 77, 87, 0.18)" }}>No</span>
                                            </div>
                                            <hr className="mt-0 mb-0" />
                                            <div className="section">
                                                <span className="button" style={{ color: "rgb(210, 77, 87)", backgroundColor: "rgba(210, 77, 87, 0.18)" }}>Low</span>
                                                <span className="button" style={{ color: "rgb(219, 176, 79)", backgroundColor: "rgba(219, 176, 79, 0.18)" }}>Medium</span>
                                                <span className="button" style={{ color: "rgb(137, 207, 240)", backgroundColor: "rgba(137, 207, 240, 0.18)" }}>High</span>
                                            </div>
                                            <hr className="mt-0 mb-0" />
                                            <div className="section">
                                                <span className="button" style={{ color: "rgb(26, 188, 156)", backgroundColor: "rgba(26, 188, 156, 0.18)" }}>more than 6 mins</span>
                                                <span className="button" style={{ color: "rgb(26, 188, 156)", backgroundColor: "rgba(26, 188, 156, 0.18)" }}>less than 6 mins</span>
                                                <span className="button" style={{ color: "rgb(210, 77, 87)", backgroundColor: "rgba(210, 77, 87, 0.18)" }}>more than 10 mins</span>
                                            </div>
                                            <hr className="mt-0 mb-0" />
                                            <div className="section">
                                                <span className="button" style={{ color: "rgb(26, 188, 156)", backgroundColor: "rgba(26, 188, 156, 0.18)" }}>2 posters</span>
                                                <span className="button" style={{ color: "rgb(210, 77, 87)", backgroundColor: "rgba(210, 77, 87, 0.18)" }}>more than 2 posters</span>
                                                <span className="button" style={{ color: "rgb(210, 77, 87)", backgroundColor: "rgba(210, 77, 87, 0.18)" }}>no posters</span>
                                            </div>
                                            <hr className="mt-0 mb-0" />
                                            <div className="section">
                                                <span className="button" style={{ color: "rgb(26, 188, 156)", backgroundColor: "rgba(26, 188, 156, 0.18)" }}>No marks</span>
                                                <span className="button" style={{ color: "rgb(210, 77, 87)", backgroundColor: "rgba(210, 77, 87, 0.18)" }}>Marks</span>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                                <div
                                    onClick={() => setPopularResponsesDropdownIsOpen(!popularResponsesDropdownIsOpen)}
                                    className="row mx-0 left-card-header top-border">
                                    Popular responses
                                    {
                                        popularResponsesDropdownIsOpen ?
                                            <IoIosArrowDown size={18} className="arrow-icon" />
                                            :
                                            <IoIosArrowForward size={18} className="arrow-icon" />
                                    }
                                </div>
                                {
                                    popularResponsesDropdownIsOpen ?
                                        <div className="responses">
                                            <div className="section">
                                                <span className="button" style={{ color: "rgb(26, 188, 156)", backgroundColor: "rgba(26, 188, 156, 0.18)" }}>Yes</span>
                                                <span className="button" style={{ color: "rgb(210, 77, 87)", backgroundColor: "rgba(210, 77, 87, 0.18)" }}>No</span>
                                                <span className="button" style={{ color: "rgb(137, 207, 240)", backgroundColor: "rgba(137, 207, 240, 0.18)" }}>40</span>
                                                <span className="button" style={{ color: "rgb(0, 187, 87)", backgroundColor: "rgba(0, 187, 87, 0.18)" }}>50</span>
                                            </div>
                                            <hr className="mt-0 mb-0" />
                                            <div className="section">
                                                <span className="button" style={{ color: "rgb(26, 188, 156)", backgroundColor: "rgba(26, 188, 156, 0.18)" }}>Yes</span>
                                                <span className="button" style={{ color: "rgb(210, 77, 87)", backgroundColor: "rgba(210, 77, 87, 0.18)" }}>No</span>
                                            </div>
                                            <hr className="mt-0 mb-0" />
                                            <div className="section">
                                                <span className="button" style={{ color: "rgb(210, 77, 87)", backgroundColor: "rgba(210, 77, 87, 0.18)" }}>Low</span>
                                                <span className="button" style={{ color: "rgb(219, 176, 79)", backgroundColor: "rgba(219, 176, 79, 0.18)" }}>Medium</span>
                                                <span className="button" style={{ color: "rgb(137, 207, 240)", backgroundColor: "rgba(137, 207, 240, 0.18)" }}>High</span>
                                            </div>
                                            <hr className="mt-0 mb-0" />
                                            <div className="section">
                                                <span className="button" style={{ color: "rgb(26, 188, 156)", backgroundColor: "rgba(26, 188, 156, 0.18)" }}>more than 6 mins</span>
                                                <span className="button" style={{ color: "rgb(26, 188, 156)", backgroundColor: "rgba(26, 188, 156, 0.18)" }}>less than 6 mins</span>
                                                <span className="button" style={{ color: "rgb(210, 77, 87)", backgroundColor: "rgba(210, 77, 87, 0.18)" }}>more than 10 mins</span>
                                            </div>
                                            <hr className="mt-0 mb-0" />
                                            <div className="section">
                                                <span className="button" style={{ color: "rgb(26, 188, 156)", backgroundColor: "rgba(26, 188, 156, 0.18)" }}>2 posters</span>
                                                <span className="button" style={{ color: "rgb(210, 77, 87)", backgroundColor: "rgba(210, 77, 87, 0.18)" }}>more than 2 posters</span>
                                                <span className="button" style={{ color: "rgb(210, 77, 87)", backgroundColor: "rgba(210, 77, 87, 0.18)" }}>no posters</span>
                                            </div>
                                            <hr className="mt-0 mb-0" />
                                            <div className="section">
                                                <span className="button" style={{ color: "rgb(26, 188, 156)", backgroundColor: "rgba(26, 188, 156, 0.18)" }}>No marks</span>
                                                <span className="button" style={{ color: "rgb(210, 77, 87)", backgroundColor: "rgba(210, 77, 87, 0.18)" }}>Marks</span>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                } */}
                                    </div>
                                </div>
                                {/* <div className="card mt-1">
                            <div className="card-body">
                                <div className="row mx-0 left-card-header px-0">
                                    <div className="col-lg-12 mt-1 mb-1">
                                        <label className="mb-0">Select start date</label>
                                        <input name="task_start_date" type="datetime-local" value={start_date} onChange={onInputFieldChanged} className="form-control" />
                                        <p className="mb-0 text-danger font-weight-normal">{start_date_error}</p>
                                    </div>
                                    <div className="col-lg-12 mt-1 mb-1">
                                        <label className="mb-0">Select due date</label>
                                        <input name="task_end_date" type="datetime-local" value={end_date} onChange={onInputFieldChanged} className="form-control" />
                                        <p className="mb-0 text-danger font-weight-normal">{end_date_error}</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                            </div>
                            <div className="col-lg-6 questionnaire-section mt-1 mb-1">
                                {
                                    sections?.map((section, key) => {
                                        return (
                                            <div className={"card section-card" + (key != 0 ? " mt-3" : "")}>
                                                <div className="title-row">
                                                    <div className="round-div" onClick={() => onSectionOpenCloseDropdownClicked(section.id)}>
                                                        {
                                                            section.isClicked ?
                                                                <FaChevronDown size={18} color="white" className="down-icon" />
                                                                :
                                                                <FaChevronRight size={18} color="white" className="right-icon" />
                                                        }
                                                    </div>
                                                    <input id={key}
                                                        name="section_name"
                                                        type="text"
                                                        className="form-control section-input font-weight-bold"
                                                        value={section.name}
                                                        onChange={onInputFieldChanged}
                                                        placeholder="Section Name"
                                                        onClick={handleSectionBoxClicked} />
                                                </div>
                                                {
                                                    section.isClicked ?
                                                        <>
                                                            <hr className="my-0" />
                                                            <div className="subsection-div">
                                                                {
                                                                    section?.sub_sections?.map((sub_section, k) => {
                                                                        return (
                                                                            <>
                                                                                <div className="title-row">
                                                                                    <div className="round-div" onClick={() => onSubSectionOpenCloseDropdownClicked(key, k)}>
                                                                                        {
                                                                                            sub_section.isClicked ?
                                                                                                <FaChevronDown size={18} color="white" className="down-icon" />
                                                                                                :
                                                                                                <FaChevronRight size={18} color="white" className="right-icon" />
                                                                                        }
                                                                                    </div>
                                                                                    <input
                                                                                        id={k}
                                                                                        name="sub_section_name"
                                                                                        type="text"
                                                                                        className="form-control section-input"
                                                                                        value={sub_section.name}
                                                                                        onChange={onInputFieldChanged}
                                                                                        onClick={() => handleSubSectionBoxClicked(key, sub_section.id)}
                                                                                        placeholder="Sub-Section Name" />
                                                                                </div>
                                                                                {
                                                                                    sub_section.isClicked ?
                                                                                        <>
                                                                                            <hr className="my-0" />
                                                                                            <div className="questions-div">
                                                                                                {
                                                                                                    sub_section.questions?.map((item, i) => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                {
                                                                                                                    item.isClicked ?
                                                                                                                        <textarea
                                                                                                                            onClick={() => handleQuestionBoxClicked(section.id, sub_section.id, item.id)}
                                                                                                                            onChange={(e) => handleQuestionChange(e, section.id, sub_section.id, item.id)}
                                                                                                                            value={item.value}
                                                                                                                            id={i}
                                                                                                                            className="form-control question-field"
                                                                                                                            placeholder="Write question here..."
                                                                                                                            rows={3}
                                                                                                                        >{item.value}</textarea>
                                                                                                                        :
                                                                                                                        <p id={i} onClick={() => handleQuestionBoxClicked(section.id, sub_section.id, item.id)} className="mb-0 pt-2 pb-2 question-field inactive-bg">{item.id}. {item.value}</p>
                                                                                                                }
                                                                                                                <div className={item.isClicked ? "" : "inactive-bg"}>
                                                                                                                    <div className="basic-field-col row mx-0 align-items-center">
                                                                                                                        {
                                                                                                                            item.answer?.type == "button" ?
                                                                                                                                <div className="row m-0 basic-field-box width-fit-content">
                                                                                                                                    <img src={ButtonIcon} />
                                                                                                                                    <p className="mb-0">&nbsp;Button</p>
                                                                                                                                </div>
                                                                                                                                :
                                                                                                                                item.answer?.type == "text" ?
                                                                                                                                    <div className="row m-0 basic-field-box width-fit-content">
                                                                                                                                        <img src={TextIcon} />
                                                                                                                                        <p className="mb-0">&nbsp;Text</p>
                                                                                                                                    </div>
                                                                                                                                    :
                                                                                                                                    item.answer?.type == "number" ?
                                                                                                                                        <div className="row m-0 basic-field-box width-fit-content">
                                                                                                                                            <img src={NumberIcon} />
                                                                                                                                            <p className="mb-0">&nbsp;Number</p>
                                                                                                                                        </div>
                                                                                                                                        :
                                                                                                                                        item.answer?.type == "date" ?
                                                                                                                                            <div className="row m-0 basic-field-box width-fit-content">
                                                                                                                                                <img src={DateIcon} />
                                                                                                                                                <p className="mb-0">&nbsp;Date</p>
                                                                                                                                            </div>
                                                                                                                                            :
                                                                                                                                            item.answer?.type == "list" ?
                                                                                                                                                <div className="row m-0 basic-field-box width-fit-content">
                                                                                                                                                    <img src={ListIcon} />
                                                                                                                                                    <p className="mb-0">&nbsp;List</p>
                                                                                                                                                </div>
                                                                                                                                                :
                                                                                                                                                item.answer?.type == "datetime" ?
                                                                                                                                                    <div className="row m-0 basic-field-box width-fit-content">
                                                                                                                                                        <img src={DateTimeIcon} />
                                                                                                                                                        <p className="mb-0">&nbsp;DateTime</p>
                                                                                                                                                    </div>
                                                                                                                                                    :
                                                                                                                                                    item.answer?.type == "radio" ?
                                                                                                                                                        <div className="row m-0 basic-field-box width-fit-content">
                                                                                                                                                            <img src={RadioIcon} />
                                                                                                                                                            <p className="mb-0">&nbsp;Radio</p>
                                                                                                                                                        </div>
                                                                                                                                                        :
                                                                                                                                                        item.answer?.type == "temperature" ?
                                                                                                                                                            <div className="row m-0 basic-field-box width-fit-content">
                                                                                                                                                                <img src={TemperatureIcon} />
                                                                                                                                                                <p className="mb-0">&nbsp;Temperature</p>
                                                                                                                                                            </div>
                                                                                                                                                            :
                                                                                                                                                            item.answer?.type == "checkbox" ?
                                                                                                                                                                <div className="row m-0 basic-field-box width-fit-content">
                                                                                                                                                                    <img src={CheckboxIcon} />
                                                                                                                                                                    <p className="mb-0">&nbsp;Checkbox</p>
                                                                                                                                                                </div>
                                                                                                                                                                :
                                                                                                                                                                item.answer?.type == "image" ?
                                                                                                                                                                    <div className="row m-0 basic-field-box width-fit-content">
                                                                                                                                                                        <img src={ImageIcon} />
                                                                                                                                                                        <p className="mb-0">&nbsp;Image</p>
                                                                                                                                                                    </div>
                                                                                                                                                                    :
                                                                                                                                                                    <></>
                                                                                                                        }
                                                                                                                        <BsTrash className="ml-auto my-2 mr-2" role="button" onClick={() => onDeleteQuestionClicked(item, key, k)} />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <hr className="my-0" />
                                                                                                            </>
                                                                                                        );
                                                                                                    })
                                                                                                }
                                                                                                <button className="add-question-btn my-1" onClick={() => addTaskBtnClicked(key, k)}>+ Add Question</button>
                                                                                            </div>
                                                                                        </>
                                                                                        :
                                                                                        <></>
                                                                                }
                                                                                <hr className="my-0" />
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                                <button className="add-question-btn my-1" onClick={() => addSubSectionBtnClicked(key)}>+ Add Sub-Section</button>
                                                            </div>
                                                        </>
                                                        :
                                                        <></>
                                                }
                                            </div>
                                        )
                                    })
                                }
                                <button className="add-question-btn my-3" onClick={addSectionBtnClicked}>+ Add Section</button>
                                {/* {questionnaire_cards.map((item, i) => {
                            return (
                                <div className="card questionnaire-card">
                                    <div className="card-body">
                                        {
                                            item.isClicked ?
                                                <textarea
                                                    onChange={handleQuestionChange}
                                                    value={item.value}
                                                    id={i}
                                                    className="form-control question-field"
                                                    placeholder="Write question here..."
                                                >{item.value}</textarea>
                                                :
                                                <p id={i} onClick={handleQuestionBoxClicked} className="mb-0 question-field">{item.id}. {item.value}</p>
                                        }
                                        <div>
                                            <div className="basic-field-col row mx-0 align-items-center">
                                                {
                                                    item.answer?.type == "button" ?
                                                        <div className="row mx-0 basic-field-box width-fit-content">
                                                            <img src={ButtonIcon} />
                                                            <p className="mb-0">&nbsp;Button</p>
                                                        </div>
                                                        :
                                                        item.answer?.type == "text" ?
                                                            <div className="row mx-0 basic-field-box width-fit-content">
                                                                <img src={TextIcon} />
                                                                <p className="mb-0">&nbsp;Text</p>
                                                            </div>
                                                            :
                                                            item.answer?.type == "number" ?
                                                                <div className="row mx-0 basic-field-box width-fit-content">
                                                                    <img src={NumberIcon} />
                                                                    <p className="mb-0">&nbsp;Number</p>
                                                                </div>
                                                                :
                                                                item.answer?.type == "date" ?
                                                                    <div className="row mx-0 basic-field-box width-fit-content">
                                                                        <img src={DateIcon} />
                                                                        <p className="mb-0">&nbsp;Date</p>
                                                                    </div>
                                                                    :
                                                                    item.answer?.type == "list" ?
                                                                        <div className="row mx-0 basic-field-box width-fit-content">
                                                                            <img src={ListIcon} />
                                                                            <p className="mb-0">&nbsp;List</p>
                                                                        </div>
                                                                        :
                                                                        item.answer?.type == "datetime" ?
                                                                            <div className="row mx-0 basic-field-box width-fit-content">
                                                                                <img src={DateTimeIcon} />
                                                                                <p className="mb-0">&nbsp;DateTime</p>
                                                                            </div>
                                                                            :
                                                                            item.answer?.type == "radio" ?
                                                                                <div className="row mx-0 basic-field-box width-fit-content">
                                                                                    <img src={RadioIcon} />
                                                                                    <p className="mb-0">&nbsp;Radio</p>
                                                                                </div>
                                                                                :
                                                                                item.answer?.type == "temperature" ?
                                                                                    <div className="row mx-0 basic-field-box width-fit-content">
                                                                                        <img src={TemperatureIcon} />
                                                                                        <p className="mb-0">&nbsp;Temperature</p>
                                                                                    </div>
                                                                                    :
                                                                                    item.answer?.type == "checkbox" ?
                                                                                        <div className="row mx-0 basic-field-box width-fit-content">
                                                                                            <img src={CheckboxIcon} />
                                                                                            <p className="mb-0">&nbsp;Checkbox</p>
                                                                                        </div>
                                                                                        :
                                                                                        item.answer?.type == "image" ?
                                                                                            <div className="row mx-0 basic-field-box width-fit-content">
                                                                                                <img src={ImageIcon} />
                                                                                                <p className="mb-0">&nbsp;Image</p>
                                                                                            </div>
                                                                                            :
                                                                                            <></>
                                                }
                                                {
                                                    i != 0 ?
                                                        <BsTrash className="ml-auto my-2" role="button" onClick={() => onDeleteQuestionClicked(item.id)} />
                                                        :
                                                        <></>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        <button className="add-question-btn" onClick={addQuestionInput}>+ Add Question</button> */}
                            </div>
                            <div className="col-lg-3 mt-1 mb-1">
                                {/* Section */}
                                {
                                    selected_section ?
                                        <>
                                            {
                                                selected_section.isClicked ?
                                                    <div className="card right-card">
                                                        <div className="card-body">
                                                            <div className="right-responses-section">
                                                                <div
                                                                    onClick={() => setSectionDropdownIsOpen(!sectionDropdownIsOpen)}
                                                                    className="row mx-0 left-card-header top-border">
                                                                    Section
                                                                    <div className="ml-auto display-flex align-items-center justify-content-right">
                                                                        <BsTrash
                                                                            className="mr-2"
                                                                            onClick={() => onDeleteSectionClicked(selected_section)}
                                                                        />
                                                                        {
                                                                            sectionDropdownIsOpen ?
                                                                                <IoIosArrowDown size={18} className="ml-2 arrow-icon" />
                                                                                :
                                                                                <IoIosArrowForward size={18} className="ml-2 arrow-icon" />
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {
                                                                    sectionDropdownIsOpen ?
                                                                        <div className="body">
                                                                            <div className="row mx-0 pt-2 pb-2">
                                                                                <div className="col-12 display-flex align-items-center">
                                                                                    {
                                                                                        selected_section.enabled ?
                                                                                            <label>Enabled&nbsp;</label>
                                                                                            :
                                                                                            <label>Disabled&nbsp;</label>
                                                                                    }
                                                                                    <Switch
                                                                                        name="section_enabled_switch"
                                                                                        onChange={handleSectionEnabledSwitchChange}
                                                                                        checked={selected_section.enabled}
                                                                                        offColor="#ccc"
                                                                                        onColor="#5cc6a3"
                                                                                        checkedIcon={false}
                                                                                        uncheckedIcon={false}
                                                                                        height={18}
                                                                                        width={35} />
                                                                                </div>
                                                                                <br />
                                                                                <div className="col-12">
                                                                                    <label className="font-weight-bold">Section help</label>
                                                                                    <textarea
                                                                                        name="section_help"
                                                                                        value={selected_section.help}
                                                                                        onChange={onInputFieldChanged}
                                                                                        className="form-control"
                                                                                    >{selected_section.help}</textarea>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    is_question_selected ?
                                                        selected_section.sub_sections?.map((subsection, subsection_key) => {
                                                            return (
                                                                subsection.isClicked ?
                                                                    subsection.questions.map((question, question_key) => {
                                                                        return (
                                                                            question.isClicked ? question && question.answer?.type ?
                                                                                <div className="card right-card">
                                                                                    <div className="card-body">
                                                                                        {/* Question */}
                                                                                        <div>
                                                                                            <div
                                                                                                onClick={() => setQuestionDropdownIsOpen(!questionDropdownIsOpen)}
                                                                                                className="row mx-0 left-card-header text-capitalize">
                                                                                                Question({question.answer?.type})
                                                                                                {
                                                                                                    questionDropdownIsOpen ?
                                                                                                        <IoIosArrowDown size={18} className="arrow-icon" />
                                                                                                        :
                                                                                                        <IoIosArrowForward size={18} className="arrow-icon" />
                                                                                                }
                                                                                            </div>
                                                                                            {
                                                                                                questionDropdownIsOpen ?
                                                                                                    <div className="body">
                                                                                                        <div className="row mx-0 pt-2 pb-2">
                                                                                                            <div className="col-6">
                                                                                                                <label className="checkbox-label">
                                                                                                                    <input name="question_mandatory_checkbox" type="checkbox" className="checkbox"
                                                                                                                        checked={question.answer?.question?.mandatory}
                                                                                                                        onChange={(e) => onInputFieldChanged(e, selected_section.id, subsection_key, question_key)}
                                                                                                                    />
                                                                                                                    <span>Mandatory</span>
                                                                                                                </label>
                                                                                                            </div>
                                                                                                            <div className="col-6 display-flex justify-content-right align-items-center">
                                                                                                                {
                                                                                                                    question.answer?.question?.enabled ?
                                                                                                                        <label>Enabled&nbsp;</label>
                                                                                                                        :
                                                                                                                        <label>Disabled&nbsp;</label>
                                                                                                                }
                                                                                                                <Switch
                                                                                                                    name="question_enabled_switch"
                                                                                                                    onChange={(e) => handleQuestionEnabledSwitchChange(e, selected_section.id, subsection_key, question_key)}
                                                                                                                    checked={question.answer?.question?.enabled}
                                                                                                                    offColor="#ccc"
                                                                                                                    onColor="#5cc6a3"
                                                                                                                    checkedIcon={false}
                                                                                                                    uncheckedIcon={false}
                                                                                                                    height={18}
                                                                                                                    width={35} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <></>
                                                                                            }
                                                                                        </div>
                                                                                        {/* Responses */}
                                                                                        <div className="right-responses-section">
                                                                                            <div
                                                                                                onClick={() => setResponsesDropdownIsOpen(!responesDropdownIsOpen)}
                                                                                                className="row mx-0 left-card-header top-border">
                                                                                                Responses
                                                                                                {
                                                                                                    responesDropdownIsOpen ?
                                                                                                        <IoIosArrowDown size={18} className="arrow-icon" />
                                                                                                        :
                                                                                                        <IoIosArrowForward size={18} className="arrow-icon" />
                                                                                                }
                                                                                            </div>
                                                                                            {
                                                                                                responesDropdownIsOpen ?
                                                                                                    <div className="body">
                                                                                                        {
                                                                                                            question.answer?.responses.map((response, response_key) => {
                                                                                                                return (
                                                                                                                    question.answer?.type == "text"
                                                                                                                        ||
                                                                                                                        question.answer?.type == "number"
                                                                                                                        ||
                                                                                                                        question.answer?.type == "date"
                                                                                                                        ||
                                                                                                                        question.answer?.type == "datetime"
                                                                                                                        ||
                                                                                                                        question.answer?.type == "image" ?
                                                                                                                        <div className="card internal-card m-2">
                                                                                                                            <div className="card-body p-0">
                                                                                                                                <input type="text" className="form-control mb-2"
                                                                                                                                    // onChange={(e) => onResponseInputFieldChanged(e, response.id)}
                                                                                                                                    value={response.value}
                                                                                                                                    disabled
                                                                                                                                />
                                                                                                                                <div className="display-flex">
                                                                                                                                    <label>Score&nbsp;</label>
                                                                                                                                    <input type="number" className="form-control score-input"
                                                                                                                                        // onChange={(e) => onResponseScoreInputFieldChanged(e, response.id)}
                                                                                                                                        value={response.score}
                                                                                                                                        disabled
                                                                                                                                    />
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        question.answer?.type == "temperature" ?
                                                                                                                            <div className="card internal-card m-2">
                                                                                                                                <div className="card-body p-0">
                                                                                                                                    <p className="mb-1">Temperature should be between :</p>
                                                                                                                                    <div className="display-flex">
                                                                                                                                        <input name="min" type="number" className="form-control score-input"
                                                                                                                                            value={response.validation.min}
                                                                                                                                            onChange={(e) => onInputFieldChanged(e, selected_section.id, subsection_key, question_key)}
                                                                                                                                        />
                                                                                                                                        <span className="ml-2 mr-2">-</span>
                                                                                                                                        <input name="max" type="number" className="form-control score-input"
                                                                                                                                            value={response.validation.max}
                                                                                                                                            onChange={(e) => onInputFieldChanged(e, selected_section.id, subsection_key, question_key)}
                                                                                                                                        />
                                                                                                                                        <select name="unit"
                                                                                                                                            value={response.unit ? response.unit : "C"}
                                                                                                                                            onChange={(e) => onInputFieldChanged(e, selected_section.id, subsection_key, question_key)}
                                                                                                                                            className="form-control ml-2 score-input">
                                                                                                                                            <option value="C"> °C </option>
                                                                                                                                            <option value="F"> °F </option>
                                                                                                                                        </select>
                                                                                                                                    </div>
                                                                                                                                    <div className="display-flex mt-2">
                                                                                                                                        <label>Score&nbsp;</label>
                                                                                                                                        <input type="number" className="form-control score-input"
                                                                                                                                            onChange={(e) => onResponseScoreInputFieldChanged(e, response_key, selected_section.id, subsection_key, question_key)}
                                                                                                                                            value={response.score} />
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            :
                                                                                                                            <div className="card internal-card m-2">
                                                                                                                                <div className="card-body p-0">
                                                                                                                                    <input type="text" className="form-control mb-2"
                                                                                                                                        onChange={(e) => onResponseInputFieldChanged(e, response_key, selected_section.id, subsection_key, question_key)}
                                                                                                                                        value={response.value} />
                                                                                                                                    <div className="display-flex align-items-center">
                                                                                                                                        <label>Score&nbsp;</label>
                                                                                                                                        <input type="number" className="form-control score-input"
                                                                                                                                            onChange={(e) => onResponseScoreInputFieldChanged(e, response_key, selected_section.id, subsection_key, question_key)}
                                                                                                                                            value={response.score} />
                                                                                                                                        {
                                                                                                                                            response_key != 0 ?
                                                                                                                                                <>
                                                                                                                                                    <BsTrash className="ml-auto" onClick={() => onDeleteResponseClicked(response, selected_section.id, subsection_key, question_key)} role="button" />
                                                                                                                                                </>
                                                                                                                                                :
                                                                                                                                                <></>
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                        {
                                                                                                            question?.answer?.type == "button"
                                                                                                                ||
                                                                                                                question?.answer?.type == "list"
                                                                                                                ||
                                                                                                                question?.answer?.type == "radio"
                                                                                                                ||
                                                                                                                question?.answer?.type == "checkbox"
                                                                                                                ?
                                                                                                                <button className="dark-btn m-2" onClick={() => addResponse(selected_section.id, subsection_key, question_key)}>+ Add Response</button>
                                                                                                                :
                                                                                                                <></>
                                                                                                        }
                                                                                                    </div>
                                                                                                    :
                                                                                                    <></>
                                                                                            }
                                                                                        </div>
                                                                                        {/* Default Choice */}
                                                                                        {
                                                                                            question.answer?.type == "button"
                                                                                                ||
                                                                                                question.answer?.type == "list"
                                                                                                ||
                                                                                                question.answer?.type == "radio"
                                                                                                ||
                                                                                                question.answer?.type == "checkbox"
                                                                                                ?
                                                                                                <div className="right-responses-section">
                                                                                                    <div
                                                                                                        onClick={() => setDefaultChoiceDropdownIsOpen(!defaultChoiceDropdownIsOpen)}
                                                                                                        className="row mx-0 left-card-header top-border">
                                                                                                        Default Choice
                                                                                                        {
                                                                                                            defaultChoiceDropdownIsOpen ?
                                                                                                                <IoIosArrowDown size={18} className="arrow-icon" />
                                                                                                                :
                                                                                                                <IoIosArrowForward size={18} className="arrow-icon" />
                                                                                                        }
                                                                                                    </div>
                                                                                                    {
                                                                                                        defaultChoiceDropdownIsOpen ?
                                                                                                            <div className="body p-2">
                                                                                                                <select name="default_choice" className="form-control" value={question.answer?.default_choice} onChange={(e) => onInputFieldChanged(e, selected_section.id, subsection_key, question_key)}>
                                                                                                                    <option value="">Select Default Choice</option>
                                                                                                                    {
                                                                                                                        question.answer?.responses?.map(response => {
                                                                                                                            return (
                                                                                                                                <option value={response.value}>{response.value}</option>
                                                                                                                            )
                                                                                                                        })
                                                                                                                    }
                                                                                                                </select>
                                                                                                            </div>
                                                                                                            :
                                                                                                            <></>
                                                                                                    }
                                                                                                </div>
                                                                                                :
                                                                                                <></>
                                                                                        }
                                                                                        {/* Question Help */}
                                                                                        <div className="right-responses-section">
                                                                                            <div
                                                                                                onClick={() => setQuestionHelpDropdownIsOpen(!questionHelpDropdownIsOpen)}
                                                                                                className="row mx-0 left-card-header top-border">
                                                                                                Question Help
                                                                                                {
                                                                                                    questionHelpDropdownIsOpen ?
                                                                                                        <IoIosArrowDown size={18} className="arrow-icon" />
                                                                                                        :
                                                                                                        <IoIosArrowForward size={18} className="arrow-icon" />
                                                                                                }
                                                                                            </div>
                                                                                            {
                                                                                                questionHelpDropdownIsOpen ?
                                                                                                    <div className="body p-2">
                                                                                                        <textarea
                                                                                                            name="question_help"
                                                                                                            value={question.answer?.question_help}
                                                                                                            onChange={(e) => onInputFieldChanged(e, selected_section.id, subsection_key, question_key)}
                                                                                                            className="form-control" placeholder="Help">{question.answer?.question_help}</textarea>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <></>
                                                                                            }
                                                                                        </div>
                                                                                        {/* Add/Edit Picture */}
                                                                                        <div className="right-responses-section">
                                                                                            <div
                                                                                                onClick={() => setAddEditPictureDropdownIsOpen(!addEditPictureDropdownIsOpen)}
                                                                                                className="row mx-0 left-card-header top-border">
                                                                                                Add/Edit Picture
                                                                                                {
                                                                                                    addEditPictureDropdownIsOpen ?
                                                                                                        <IoIosArrowDown size={18} className="arrow-icon" />
                                                                                                        :
                                                                                                        <IoIosArrowForward size={18} className="arrow-icon" />
                                                                                                }
                                                                                            </div>
                                                                                            {
                                                                                                addEditPictureDropdownIsOpen ?
                                                                                                    <div className="body p-2">
                                                                                                        <div>
                                                                                                            <div className="Add-photo">
                                                                                                                <span className="ng-star-inserted">
                                                                                                                    <label className="ng-star-inserted">
                                                                                                                        {
                                                                                                                            question.help_image ?
                                                                                                                                <div
                                                                                                                                    className="AddBtn pt-0">
                                                                                                                                    <div
                                                                                                                                        onMouseOver={() => onHelpImageHover(selected_section.id, subsection_key, question_key)}
                                                                                                                                    >
                                                                                                                                        <img src={question.help_image} className="img img-fluid" />
                                                                                                                                    </div>
                                                                                                                                    {
                                                                                                                                        question.help_image_hover ?
                                                                                                                                            <div
                                                                                                                                                onClick={() => onDeleteHelpImageClicked(selected_section.id, subsection_key, question_key)}
                                                                                                                                                onMouseOut={() => onHelpImageUnHover(subsection_key, question_key)}
                                                                                                                                                className="DeleteLayout">
                                                                                                                                                <FaTrash />
                                                                                                                                            </div>
                                                                                                                                            :
                                                                                                                                            <></>
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                                :
                                                                                                                                <div
                                                                                                                                    onClick={() => onUploadPhotoClicked(question.id)}
                                                                                                                                    className="AddBtn">
                                                                                                                                    <div>
                                                                                                                                        <FaPlus size={18} />
                                                                                                                                        <div>Upload</div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                        }
                                                                                                                    </label>
                                                                                                                    <input
                                                                                                                        id={`help_img_${question.id}`}
                                                                                                                        type="file"
                                                                                                                        accept="image/jpg,image/png,image/jpeg"
                                                                                                                        style={{ display: "none" }}
                                                                                                                        onChange={(e) => fileChangedHandler(e, selected_section.id, subsection_key, question_key)}
                                                                                                                    />
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <></>
                                                                                            }
                                                                                        </div>
                                                                                        {/* Email Notification */}
                                                                                        {/* <div className="right-responses-section">
                                                                                    <div
                                                                                        onClick={() => setEmailNotificationDropdownIsOpen(!emailNotificationDropdownIsOpen)}
                                                                                        className="row mx-0 left-card-header top-border">
                                                                                        Email Notification
                                                                                        {
                                                                                            emailNotificationDropdownIsOpen ?
                                                                                                <IoIosArrowDown size={18} className="arrow-icon" />
                                                                                                :
                                                                                                <IoIosArrowForward size={18} className="arrow-icon" />
                                                                                        }
                                                                                    </div>
                                                                                    {
                                                                                        emailNotificationDropdownIsOpen ?
                                                                                            <div className="body p-2">
                                                                                                <div className="row mx-0">
                                                                                                    <label>Notification Email Required?&nbsp;</label>
                                                                                                    <Switch
                                                                                                        onChange={(e) => handleEmailNotificationSwitchChange(e, selected_section.id, subsection_key, question_key)}
                                                                                                        checked={question.answer?.email_notification}
                                                                                                        offColor="#ccc"
                                                                                                        onColor="#5cc6a3"
                                                                                                        checkedIcon={false}
                                                                                                        uncheckedIcon={false}
                                                                                                        height={18}
                                                                                                        width={35} />
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            <></>
                                                                                    }
                                                                                </div> */}
                                                                                        {/* Action Plan */}
                                                                                        {/* <div className="right-responses-section">
                                                                                    <div
                                                                                        onClick={() => setActionPlanDropdownIsOpen(!actionPlanDropdownIsOpen)}
                                                                                        className="row mx-0 left-card-header top-border">
                                                                                        Action Plan
                                                                                        {
                                                                                            actionPlanDropdownIsOpen ?
                                                                                                <IoIosArrowDown size={18} className="arrow-icon" />
                                                                                                :
                                                                                                <IoIosArrowForward size={18} className="arrow-icon" />
                                                                                        }
                                                                                    </div>
                                                                                    {
                                                                                        actionPlanDropdownIsOpen ?
                                                                                            <div className="body p-2">
                                                                                                <div className="row mx-0">
                                                                                                    <label>Action Plan Required?&nbsp;</label>
                                                                                                    <Switch
                                                                                                        onChange={(e) => handleActionPlanSwitchChange(e, selected_section.id, subsection_key, question_key)}
                                                                                                        checked={question.answer?.action_plan}
                                                                                                        offColor="#ccc"
                                                                                                        onColor="#5cc6a3"
                                                                                                        checkedIcon={false}
                                                                                                        uncheckedIcon={false}
                                                                                                        height={18}
                                                                                                        width={35} />
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            <></>
                                                                                    }
                                                                                </div> */}
                                                                                        {/* Tags */}
                                                                                        {/* <div className="right-responses-section">
                                                                                    <div
                                                                                        onClick={() => setTagsDropdownIsOpen(!tagsDropdownIsOpen)}
                                                                                        className="row mx-0 left-card-header top-border">
                                                                                        Tags
                                                                                        {
                                                                                            tagsDropdownIsOpen ?
                                                                                                <IoIosArrowDown size={18} className="arrow-icon" />
                                                                                                :
                                                                                                <IoIosArrowForward size={18} className="arrow-icon" />
                                                                                        }
                                                                                    </div>
                                                                                    {
                                                                                        tagsDropdownIsOpen ?
                                                                                            <div className="body p-2">
                                                                                                <select className="form-control">
                                                                                                    <option value="">Select a Tag</option>
                                                                                                </select>
                                                                                            </div>
                                                                                            :
                                                                                            <></>
                                                                                    }
                                                                                </div> */}
                                                                                        {/* <div className="row mx-0 left-card-header px-0">
                                                                                    <div className="col-lg-12 mt-1 mb-1">
                                                                                        <label className="mb-0">Select start date</label>
                                                                                        <input name="question_start_date" type="datetime-local" value={question.start_date} onChange={onInputFieldChanged} className="form-control" />
                                                                                    </div>
                                                                                    <div className="col-lg-12 mt-1 mb-1">
                                                                                        <label className="mb-0">Select due date</label>
                                                                                        <input name="question_end_date" type="datetime-local" value={question.end_date} onChange={onInputFieldChanged} className="form-control" />
                                                                                    </div>
                                                                                </div> */}
                                                                                        {/* Save/Cancel */}
                                                                                        {/* <hr className="mt-0 mb-0" />
                                                                                <div className="right-responses-section">
                                                                                    <div className="body p-2 display-flex">
                                                                                        <button className="mr-2 success-btn" onClick={onSaveBtnClicked}>Save</button>
                                                                                        <button className="ml-2 cancel-btn">Cancel</button>
                                                                                    </div>
                                                                                </div> */}
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                                :
                                                                                <></>
                                                                        )
                                                                    })
                                                                    :
                                                                    <></>
                                                            )
                                                        })
                                                        :
                                                        selected_section.sub_sections?.map(sub_section => {
                                                            if (sub_section.isClicked) {
                                                                return (
                                                                    <div className="card right-card">
                                                                        <div className="card-body">
                                                                            <div className="right-responses-section">
                                                                                <div
                                                                                    onClick={() => setSubsectionDropdownIsOpen(!subsectionDropdownIsOpen)}
                                                                                    className="row mx-0 left-card-header top-border">
                                                                                    Sub-Section
                                                                                    <div className="ml-auto display-flex align-items-center justify-content-right">
                                                                                        <BsTrash
                                                                                            className="mr-2"
                                                                                            onClick={() => onDeleteSubSectionClicked(selected_section.id, sub_section)}
                                                                                        />
                                                                                        {
                                                                                            subsectionDropdownIsOpen ?
                                                                                                <IoIosArrowDown size={18} className="ml-2 arrow-icon" />
                                                                                                :
                                                                                                <IoIosArrowForward size={18} className="ml-2 arrow-icon" />
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    subsectionDropdownIsOpen ?
                                                                                        <div className="body">
                                                                                            <div className="row mx-0 pt-2 pb-2">
                                                                                                <div className="col-12 display-flex align-items-center">
                                                                                                    {
                                                                                                        sub_section.enabled ?
                                                                                                            <label>Enabled&nbsp;</label>
                                                                                                            :
                                                                                                            <label>Disabled&nbsp;</label>
                                                                                                    }
                                                                                                    <Switch
                                                                                                        name="section_enabled_switch"
                                                                                                        onChange={handleSubSectionEnabledSwitchChange}
                                                                                                        checked={sub_section.enabled}
                                                                                                        offColor="#ccc"
                                                                                                        onColor="#5cc6a3"
                                                                                                        checkedIcon={false}
                                                                                                        uncheckedIcon={false}
                                                                                                        height={18}
                                                                                                        width={35} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        <></>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                            }
                                        </>
                                        :
                                        <></>
                                }
                                {
                                    usersBarIsOpen ?
                                        <div className="card right-card">
                                            <div className="card-body">
                                                <div className="right-responses-section">
                                                    <div className="row mx-0 left-card-header top-border">
                                                        Assign Users
                                                    </div>
                                                    <div className="body">
                                                        <div className="row mx-0 pt-2 pb-2">
                                                            <div className="col-12 p-0 text-center">
                                                                {
                                                                    users_loading ?
                                                                        <div class="spinner-grow text-secondary" role="status">
                                                                            <span class="sr-only">Loading...</span>
                                                                        </div>
                                                                        :
                                                                        <div className="locations">
                                                                            {
                                                                                location_users?.map((location, location_key) => {
                                                                                    return (
                                                                                        <div key={location_key}>
                                                                                            <div
                                                                                                onClick={() => onLocationClicked(location_key)}
                                                                                                className="location-row d-flex align-items-center justify-content-between">
                                                                                                <label className="font-weight-700">{location.location_name}</label>
                                                                                                {
                                                                                                    location.isClicked ?
                                                                                                        <FaChevronUp />
                                                                                                        :
                                                                                                        <FaChevronDown />
                                                                                                }
                                                                                            </div>
                                                                                            {
                                                                                                location.isClicked ?
                                                                                                    <div className="roles pl-3">
                                                                                                        {
                                                                                                            location.roles?.map((role, role_key) => {
                                                                                                                return (
                                                                                                                    <>
                                                                                                                        <div
                                                                                                                            className="roles-row d-flex align-items-center">
                                                                                                                            <div className="d-flex align-items-center">
                                                                                                                                <label>
                                                                                                                                    <input
                                                                                                                                        name="role_chkbox"
                                                                                                                                        type="checkbox"
                                                                                                                                        value={role.role_id}
                                                                                                                                        checked={role.isChecked}
                                                                                                                                        onClick={() => onRoleCheckboxClicked(location_key, role_key)}
                                                                                                                                    />
                                                                                                                                    <label className="ml-2">{role.role_name}</label>
                                                                                                                                </label>
                                                                                                                            </div>
                                                                                                                            {
                                                                                                                                role.isClicked ?
                                                                                                                                    <FaChevronUp
                                                                                                                                        onClick={() => onRoleClicked(location_key, role_key)}
                                                                                                                                        className="ml-auto" />
                                                                                                                                    :
                                                                                                                                    <FaChevronDown
                                                                                                                                        onClick={() => onRoleClicked(location_key, role_key)}
                                                                                                                                        className="ml-auto" size={16} />
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                        {
                                                                                                                            role.isClicked ?
                                                                                                                                <div className="users ml-3">
                                                                                                                                    {
                                                                                                                                        role.users?.map((user, user_key) => {
                                                                                                                                            return (
                                                                                                                                                <div key={user_key} className="users-row d-flex align-items-center">
                                                                                                                                                    <label>
                                                                                                                                                        <input
                                                                                                                                                            name="user_chkbox"
                                                                                                                                                            type="checkbox"
                                                                                                                                                            value={user.uid._id}
                                                                                                                                                            checked={user.uid.isChecked}
                                                                                                                                                            onClick={() => onUserCheckboxClicked(location_key, role_key, user_key)}
                                                                                                                                                        />
                                                                                                                                                        <label className="ml-2">{user.uid.user_name}</label>
                                                                                                                                                    </label>
                                                                                                                                                </div>
                                                                                                                                            )
                                                                                                                                        })
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                                :
                                                                                                                                <></>
                                                                                                                        }
                                                                                                                    </>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </div>
                                                                                                    :
                                                                                                    <></>
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                    </>
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.settingsLocation.isLoading,
        locationData: state.settingsLocation.locationData
    }
};
const ConnectWith = connect(mapStateToProps, {
    fetchData
})(EditQuestionnaire);
export default withStyles(styles)(ConnectWith);
