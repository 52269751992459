import { feedbackColor, eLearningColor, todoColor, schedulerColor,newsandupdate,ovChatColor,ovRoutineColor, ovHrColor, ratingColor } from "../config";
import {
  feedback_white,
  elearning_white,
  elearning_asad2,
  elearning_asad3,
  schedule_asad,
  feedback_asad,
  routine_asad,
  news_asad,
  chat_asad,
  hr_asad,
  star_asad,
  scheduler_white,
  todo_white,
  news_update,
  ovChat,
  ovRoutine,
  ovHR
} from "../icons";

import axios from "axios";
import React, { Component } from "react";
import Cookies from "universal-cookie";
import { userPermissions } from "../pages/common/Roles/modules/actions";
import { connect } from "react-redux";
const cookies = new Cookies();
const headers = {
  'Content-Type': 'application/json',
  'Authorization': cookies.get('ovToken')
}

// export class NewProductColor extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//         eLearnigProduct : "",
//         feedBackProduct : "",
//         teamSchedulerProduct : "" ,
//         toDoProduct : "",
//         newsProduct : "" ,
//         chatProduct : "",
//         ovHrProduct : "",
//         ratingProduct : "",
//         ovRoutingProduct : "",
//     };
//   }

//   componentDidMount = () => {
//     console.log("aaabbb vrati")
//      axios.get(`https://dev.onevision.io/nodejs/onevision/role/permissions`, {headers: headers
//     }).then((response) => 
//     console.log("response abc",response)
//     .catch((error) => console.log("error",error))
//     )
//   //   rolesServices.userPermissions().then(response => {
//   //     console.log("response abc",response)
//   //     if (response.data.success === 2) {
//   //         alert.error(response.data.message)
//   //     } else {
//   //         // this.props.getScheduleData(this.props.scheduler_lid)
//   //         alert.success(response.data.message)
//   //     }
//   // });
//   };


//   // render() {
//   //   const {
//   //     permissionsLoading,
//   //     permissionsData,
//   //   } = this.props;
//   //   console.log("permissionsData",this.props.permissionsData)
//   //   // const { activeProduct } = this.state;
//   //   // var ovUserName = cookies.get("ovUserName");
//   //   // var ovUserImage = cookies.get("ovUserImage");

//   //   if (permissionsLoading) return <LoadingData />;
//   //   return (
//   //       <>
//   //           <div>
//   //               {permissionsData.map((item) => {
//   //                   if(item.product_name === "feedback") 
//   //                   {
//   //                       // this.setState({
//   //                       //     feedBackProduct: item.color_code
//   //                       // })
//   //                       feedBackProduct = item.color_code
//   //                   }else if (item.product_name === "eLearning")
//   //                   {
//   //                       // this.setState({
//   //                           eLearnigProduct= item.color_code
//   //                       // })  
//   //                   }else if (item.product_name === "ovHr")
//   //                   {
//   //                       // this.setState({
//   //                           ovHrProduct = item.color_code
//   //                       // })  
//   //                   }else if (item.product_name === "todo")
//   //                   {
//   //                       // this.setState({
//   //                           toDoProduct = item.color_code
//   //                       // })  
//   //                   }else if (item.product_name === "ovRoutine")
//   //                   {
//   //                       // this.setState({
//   //                           ovRoutingProduct = item.color_code
//   //                       // })  
//   //                   }else if (item.product_name === "ovChat")
//   //                   {
//   //                       // this.setState({
//   //                           chatProduct = item.color_code
//   //                       // })  
//   //                   }else if (item.product_name === "scheduler")
//   //                   {
//   //                       // this.setState({
//   //                           teamSchedulerProduct = item.color_code
//   //                       // })  
//   //                   }else if (item.product_name === "rating")
//   //                   {
//   //                       // this.setState({
//   //                           ratingProduct = item.color_code
//   //                       // })  
//   //                   }
//   //                   else {
//   //                       // this.setState({
//   //                           newsProduct = item.color_code
//   //                       // })
//   //                   }
//   //               })}
//   //           </div>
//   //       </>
//   //   );
//   // }
// }
// const mapStateToProps = (state) => ({
//   permissionsLoading: state.settingsRoles.permissionsLoading,
//   permissionsData: state.settingsRoles.permissionsData,
// });

// const ConnectWith = connect(mapStateToProps, {
//   userPermissions,
// })(NewProductColor);

export default [
  {
    title: 'eLearning',
    href: window.innerWidth >= 768 ? '/elearning/dashboard' : '/elearning/get-started',
    // backgroundColor: 'elearning.main',
    backgroundColor: 'white',
    // iconColor: "rgb(211,211,211,.2)",
    imageUrl: elearning_asad2
  },
  {
    title: 'Feedback',
    href: '/feedback/dashboard/new',
    backgroundColor: 'white',
    // backgroundColor: feedbackColor.main,
    // iconColor: "rgb(211,211,211,.2)",
    imageUrl: feedback_asad
  },
  {
    title: 'Scheduler',
    href: '/scheduler/dashboard',
    backgroundColor: 'white',
    // backgroundColor: schedulerColor.main,
    // iconColor: "rgb(211,211,211,.2)",
    imageUrl: schedule_asad
  },
  // {
  //   title: 'Todo',
  //   href: '/todo/dashboard/personal',
  //   backgroundColor: 'white',
  //   // backgroundColor: todoColor.main,
  //   // iconColor: "rgb(211,211,211,.2)",
  //   imageUrl: todo_white
  // },
  {
    title: 'News',
    href: '/newsandupdate/mynews',
    // backgroundColor: newsandupdate.main,
    backgroundColor: 'white',
    // iconColor: "rgb(211,211,211,.2)",
    imageUrl: news_asad
  },
  {
    title: 'Chat',
    href: "/chat/",
    // backgroundColor: ovChatColor.main,
    backgroundColor: 'white',
    // iconColor: "rgb(211,211,211,.2)",
    imageUrl: chat_asad
  },
  {
    title: 'Forms',
    href: "/routine/dashboard",
    backgroundColor: 'white',
    // backgroundColor: ovRoutineColor.main,
    // iconColor: "rgb(211,211,211,.2)",
    imageUrl: routine_asad
  },
  {
    title: 'HR',
    href: "/hr/hierarachy_chart",
    // backgroundColor: ovHrColor.main,
    backgroundColor: 'white',
    // iconColor: "rgb(211,211,211,.2)",
    imageUrl: hr_asad
  },
  {
    title: 'Team Feedback',
    href: '/ovRating/RateTeam',
    backgroundColor: 'white',
    // backgroundColor: ratingColor.main,
    // iconColor: "rgb(211,211,211,.2)",
    imageUrl: star_asad
  },
];
// (ConnectWith);



