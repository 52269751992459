import { MoNGO_DB_API_URL, HEADER_TOKEN, API_URL, MY_TOKEN } from "../../../../../config";
import axios from 'axios';

export const companyServices = {
    fetchData,
    fetchForm,
    fetchUsers,
    saveImage
}

function fetchData() {
    return axios.get(`${MoNGO_DB_API_URL}/company`, HEADER_TOKEN).then(response => response)
};

function fetchForm(id) {
    return axios.get(`${API_URL}/routine/form/${id}`, HEADER_TOKEN).then(response => response)
}

function fetchUsers(body) {
    return axios.post(`${API_URL}/routine/location/users`, body, HEADER_TOKEN).then(response => response);
}

function saveImage(image) {
    const data = new FormData();
    data.append("image", image, "image");
    var config = {
        method: 'post',
        url: `${API_URL}/routine/user/forms/image/save`,
        headers: {
            'Authorization': MY_TOKEN
        },
        data: data
    };
    return axios(config).then(response => response)
}