import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { TableRecordNotFound, LoadingData, MyEditor } from '../../../components'
import './setting.css';
import { connect } from "react-redux";
import { userPermissions } from '../../../pages/common/Roles/modules/actions';
import EditIcon from '@mui/icons-material/Edit';
import { stableSort, getComparator } from '../../../utilities/TableFunctions';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Collapse from "@material-ui/core/Collapse";
import ExportDownload from "./ExportDownload"
import {
    Tooltip,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    Button,
    FormControl,
    Select,
    MenuItem,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
    Paper,
    Grid,
}
    from '@material-ui/core';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { schedulerColor } from "../../../config";
import { sc_image_noavailability as noactvtylog } from '../../../icons'
import moment from "moment";
import ExportPE from "./ExportPE";
import ExportCash from './ExportCash';
import ExportCsv from './ExportCsv';
import { payrollSummaryServices } from './modules/services';
import { fetchData } from './modules/actions';
import SlickSilder from "./SlickSilder";
import EditTableModal from './EditTableColumn';
import { alert } from '../../../utilities';
import WarningIconModal from "./WarningIconModal";
import { getScheduleData } from '../Settings/modules/actions';
import UserListModal from "./UserListModal";
import Checkbox from '@mui/material/Checkbox';
import { history } from "../../../history";
import { Link } from "react-router-dom";

const styles = (theme) => ({
    root: {
        padding: theme.spacing(0),
    },
    textTitle: {
        fontSize: '15px',
        color: '#262626',
        fontWeight: 550,
        margin: '7px 0px 4px'
    }, hidscroll: {
        '&::-webkit-scrollbar': {
            display: "none"
        }
    },
    Download: {
        padding: '7px 30px',
        marginLeft: '3px',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        }
    },
    headCell: {
        padding: '0.5rem 0rem',
        color: "#000000",
        //color: '#ffffff',
        fontWeight: 550,
        textTransform: 'capitalize',
        fontSize: '13px'
    },
    rootToolbar: {
        minHeight: '58px',
        padding: theme.spacing(0, 2)
    },
    title: {
        flex: '1 1 100%',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    textareaContainer: {
        position: "relative",
        marginTop: 15,
        marginRight: 15
    },
    textareacontainertextarea: {
        display: "block",
        width: "100% !important",
        resize: "vertical",
        height: "130px",
        background: '#F3EDED'
    },
    textareacontainerbutton: {
        padding: "5px 16px",
        fontSize: "19px",
        position: "absolute",
        color: "white",
        backgroundColor: "#8E0C0C",
        borderRadius: 4,
        right: "9px",
        bottom: "26px",
        height: 40
    },
    avatar: {
        marginRight: "10px",
        float: "left",
        textTransform: "uppercase",
        height: "40px",
        width: "40px",
        fontWeight: "600",
        fontSize: "15px",
        backgroundColor: schedulerColor.main,
        color: "white",
    },
    user_name: {
        // maxWidth: "50px",
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: '#323C47',
        fontSize: '12px',
        fontWeight: 600,
        float: 'left',
        lineHeight: 3
    },
    card: {
        padding: '1rem',
        marginBottom: '1rem',
    },
    textBody: {
        color: '#7C7C7C',
        fontWeight: '400'
    },
    TableCell: {
        color: '#707683',
    }

});

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: " 70%",
    width: "74%",
    overflow: "scroll",
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: " 35%",
    width: "50%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};


class Company extends Component {
    constructor(props) {
        super(props)
        this.state = {
            payrollPEData: [],
            isLoading: false,
            myItem: {},
            order: 'asc',
            orderBy: "uid",
            commentsData: "",
            EditTableModal: false,
            usersNameData: [],
            errors: {},
            errorsHelper: {},
            openNow: false,
            Name: true,
            is_Last_Name: true,
            is_Payroll_ID: true,
            is_Role: true,
            is_Payroll_rule: true,
            is_Regular_Hours: true,
            is_Scheduled_Hours: true,
            is_Actual_Hours: true,
            is_Unpaid_Breaks: true,
            is_Actual_Scheduled_Diff: true,
            is_Total_Paid_Hours: true,
            is_Estimated_Wages: true,
            assignLocationData: [],
            newStateAR: [],
            checked: false,
            locationLoader: false,
            payrollCashData:[],
            is_select_card: "",
            is_warning_modal: false,
            tempState: [
                { id: 'user_name', content: "Name", numeric: false, status: true },
                { id: 'role', content: "Role", numeric: false, status: true },
                { id: 'payroll_rule', content: "Payroll Rule", numeric: false, status: true },
                { id: 'total_scheduled_hrs', content: "Scheduled Hrs", numeric: false, status: true },
                { id: 'total_actual_hrs', content: "Actual Hrs", numeric: false, status: true },
                { id: 'total_paid_hrs', content: "Total Paid", numeric: false, status: true },
                { id: 'total_unsch Hrs', content: "Unsch Hrs", numeric: false, status: true },
                { id: 'total_break_hrs', content: "Break Diff", numeric: false, status: true },
                { id: 'total_est_wages', content: "Est Wages", numeric: false, status: true },
                { id: 'rating_manual', content: "Manual Hours", numeric: false, status: true },
                { id: 'rating_correction', content: "Correction Req", numeric: false, status: true },
                { id: 'rating_overtime', content: "Overtime Hrs", numeric: false, status: true },
                { id: 'rating_regular', content: "Regular Hrs", numeric: false, status: true },
                { id: 'rating_holiday', content: "Holiday Hrs", numeric: false, status: true },],
            // items: [
            //     { id: 'user_name', content: "Name", numeric: false, status: true },
            //     { id: 'role', content: "Role", numeric: false, status: true },
            //     { id: 'public_holiday', content: "Public Holiday", numeric: false, status: true },
            //     { id: 'payroll_rule', content: "Payroll Rule", numeric: false, status: true },
            //     { id: 'employee_id', content: "Employee IDs", numeric: false, status: true },
            //     { id: 'template_name', content: "Template Name", numeric: false, status: true },
            //     { id: 'total_scheduled_hrs', content: "Scheduled Hrs", numeric: false, status: true },
            //     { id: 'total_actual_hrs', content: "Actual Hrs", numeric: false, status: true },
            //     { id: 'total_paid_hrs', content: "Total Paid", numeric: false, status: true },
            //     { id: 'total_unsch Hrs', content: "Unsch Hrs", numeric: false, status: true },
            //     { id: 'total_break_hrs', content: "Break Diff", numeric: false, status: true },
            //     { id: 'total_est_wages', content: "Est Wages", numeric: false, status: true },
            //     { id: 'rating_manual', content: "Manual Hours", numeric: false, status: true },
            //     { id: 'rating_correction', content: "Correction Req", numeric: false, status: true },
            //     { id: 'rating_overtime', content: "Overtime Hrs", numeric: false, status: true },
            //     { id: 'rating_regular', content: "Regular Hrs", numeric: false, status: true },
            //     { id: 'rating_holiday', content: "Holiday Hrs", numeric: false, status: true },]
        }
    };

    componentDidMount() {
        var isPermissionReload = Object.keys(this.props.permissionsData).length > 0 ? false : true;
        this.props.userPermissions(isPermissionReload);
        this.getDetailsPayroll();



    };

    // closeEditTableModal = () => {
    //     this.setState({
    //         EditTableModal: false
    //     })
    // };




    // openEditTableModal = () => {
    //     this.setState({
    //         EditTableModal: true
    //     })
    //     // var dataPayload = this.props.lid
    //     // payrollSummaryServices.getTableAdData(dataPayload).then((response) => {
    //     //     if(response.data.HeaderData.length>0){
    //     //         var newObjTT = response.data.HeaderData.map((itemd) => {
    //     //             var objT = {
    //     //                 id: itemd.header_name, content: itemd.header_name, numeric: false, status: itemd.status === 0 ? false : true 
    //     //             }
    //     //             return objT
    //     //         })
    //     //         console.log("newObjTT",newObjTT)
    //     //         this.setState({
    //     //             items: newObjTT
    //     //         })
    //     //     } 
    //     // })
    // };

    // getUserDetails = (dates) => {
    //     console.log("hjvkvj",this.props.MonthData)
    //     var currentdate= this.props.MonthData.map((item) => {
    //         var startDate = item.monthDate.split(',').shift()
    //         console.log("kvfk",startDate)
    //         var endDate = item.monthDate.split(',').pop()
    //         console.log("kvfk",endDate)
    //         var date =  moment(new Date()).format("YYYY-MM-DD")
    //         console.log("kvfk",date)
    //         var dd = date >= startDate && date <= endDate 
    //         console.log("kvfk",dd)
    //     })
    //     console.log("bvjvfkj",currentdate)
    //     this.setState({
    //         payrollLoading: true,
    //     }, () => {
    //         var data = {
    //             lid: this.props.lid,
    //             date: dates ? dates : this.props.MonthData[0].monthDate
    //         }
    //         payrollSummaryServices.fetchDetails(data).then(response => {
    //             if (response.data.success === 2) {
    //             } else {
    //                 this.setState({
    //                     Activity_log: response.data.Activity_log,
    //                     AprroveData: response.data.AprroveData,
    //                     payrollData: response.data.PayrollData,
    //                     usersData: response.data.usersData,
    //                     newDataResUserData: response.data.usersData,
    //                     payrollLoading: false
    //                 })
    //             }
    //         });
    //     });
    // };

    payrollComment = (id, dates) => {
        this.setState({
            payrollLoading: true,
        }, () => {
            var data = {
                activity_id: id,
                comment: this.state.commentsData,
            }
            var nameArr = dates.split(',');
            var dataNew = {
                activity_id: null,
                comment: this.state.commentsData,
                lid: this.props.lid,
                payroll_startDate: nameArr?.[0],
                payroll_endDate: nameArr?.[1],
            }
            payrollSummaryServices.fetchPayrollComment(id === null ? dataNew : data).then(response => {
                if (response.data.success === 2) {
                } else {
                    this.setState({
                        payrollLoading: false
                    })
                    alert.success(response.data.message)
                    this.props.getUserDetails(dates);
                    this.props.getScheduleData();
                    this.setState({
                        commentsData: ""
                    })
                }
            });
        });

    };

    // handleChangeChecked = (e, index) => {
    //     const a = this.props.items && this.props.items.forEach((element) => {
    //         if (element.id === index) {
    //             element.status = e.target.checked
    //         }
    //     });

    //     this.setState({
    //         item: a,
    //     })
    //     var dataase = this.props.items && this.props.items.map((option, index) => {
    //         var newobjs = {
    //             header_name: option.content,
    //             status: option.status === true ? 1 : 0,
    //             index: index
    //         }
    //         return newobjs
    //     })
    //     this.setState({
    //         newStateAR: dataase
    //     })
    // }

    // saveCloumnRow = (array) => {
    //     this.setState({
    //         items: array
    //     })
    //     var dataPayload = {
    //         lid: this.props.lid,
    //         headerArr: this.state.newStateAR
    //     }
    //     payrollSummaryServices.getTableAd(dataPayload).then(response => {
    //         if (response.data.success === 2) {
    //             alert.error(response.data.message)
    //         } else {
    //             this.setState({
    //                 items: this.state.items
    //             })
    //             this.closeEditTableModal();
    //             var dataPayload = this.props.lid
    //             // payrollSummaryServices.getTableAdData(dataPayload).then((response) => {
    //             //     if (response.data.HeaderData.length > 0) {
    //             //         var newObjTT = response.data.HeaderData.map((itemd) => {
    //             //             var objT = {
    //             //                 id: itemd.header_name, content: itemd.header_name, numeric: false, status: itemd.status === 0 ? false : true
    //             //             }
    //             //             return objT
    //             //         })

    //             //         this.setState({
    //             //             items: newObjTT
    //             //         })
    //             //     }
    //             // })
    //             this.props.getUserDetails()
    //             // this.props.fetchData(this.props.scheduler_lid)
    //             alert.success(response.data.message)
    //         }
    //     });
    //     // this.closeEditTableModal();
    // };

    warningModalOpen = () => {
        this.setState({
            is_warning_modal: true
        })
    };

    warningModalClose = () => {
        this.setState({
            is_warning_modal: false
        })
    };

  

    getDetailsPayroll = () => {
        payrollSummaryServices.getDetailsPayrollCash().then(response => {
            if (response.data.success === 1) {
                this.setState({
                    payrollCashData: response.data.PayrollData
                })
            }
        });
    };

    getCorrectionWarning = (dates, uid) => {
        this.warningModalOpen();
        this.setState({
            isLoading: true
        })
        var data = {
            lid: this.props.lid,
            date: dates,
            uid: uid
        }
        payrollSummaryServices.warningCorrectionRequest(data).then(response => {
            if (response.data.success === 1) {
                this.setState({
                    correctionWarningData: response.data.CorrectionRequest
                })
                this.setState({
                    isLoading: false
                })
            }
        });
    };

    createSortHandler = (property) => (event) => {
        const isAsc = this.state.orderBy === property && this.state.order === 'asc';
        this.setState({
            order: isAsc ? 'desc' : 'asc',
            orderBy: property
        })
    };

    onChangeComment = (value) => {
        this.setState({
            commentsData: value
        })
    };

    // clickCard = (row) => {
    //     this.setState({
    //         payrollLoading: true,
    //         is_select_card: row.Date
    //     }, () => {
    //         var data = {
    //             lid: this.props.lid,
    //             date: row.monthDate
    //         }
    //         payrollSummaryServices.fetchDetails(data).then(response => {
    //             console.log('banana', response.data)

    //             if (response.data.success === 2) {
    //             } else {
    //                 this.setState({
    //                     Activity_log: response.data.Activity_log,
    //                     AprroveData: response.data.AprroveData,
    //                     payrollData: response.data.PayrollData,
    //                     usersData: response.data.usersData,
    //                     payrollLoading: false
    //                 })
    //             }
    //         });
    //     });
    // };

    getData = (row) => {
        this.setState({
            getDate: row,
        })
    };

    onApprovalClick = (dates, level) => {
        this.setState({
            payrollLoading: true,
        }, () => {
            var nameArr = dates.split(',');
            var data = {
                lid: this.props.lid,
                payroll_startDate: nameArr?.[0],
                payroll_endDate: nameArr?.[1],
                aprove_level: level,
            }
            payrollSummaryServices.fetchApproveData(data).then(response => {
                if (response.data.success === 0) {
                    alert.error(response.data.message)
                    this.props.getUserDetails(dates);
                    this.props.getScheduleData();
                } else {
                    this.setState({
                        approvalData: response.data.message,
                        payrollLoading: false
                    })
                    alert.success(response.data.message)
                    this.props.getUserDetails(dates);
                    this.props.getScheduleData();
                }
            });
        });
    };



    onRejectClick = (dates, level) => {
        this.setState({
            payrollLoading: true,
        }, () => {
            var nameArr = dates.split(',');
            var data = {
                lid: this.props.lid,
                payroll_startDate: nameArr?.[0],
                payroll_endDate: nameArr?.[1],
                reject_level: level,
            }
            payrollSummaryServices.rejectApprovalData(data).then(response => {
                if (response.data.success === 2) {
                } else {
                    this.setState({
                        approvalData: response.data.message,
                        payrollLoading: false
                    })
                    alert.error(response.data.message)
                    this.props.getUserDetails(dates);
                    this.props.getScheduleData();
                }
            });
        });
    };

    onClickReset = (dates) => {
        this.setState({
            payrollLoading: true,
        }, () => {
            var nameArr = dates.split(',');
            var data = {
                lid: this.props.lid,
                payroll_startDate: nameArr?.[0],
                payroll_endDate: nameArr?.[1],
            }
            payrollSummaryServices.resetApprovalData(data).then(response => {
                if (response.data.success === 2) {
                } else {
                    this.setState({
                        approvalData: response.data.message,
                        payrollLoading: false
                    })
                    alert.success(response.data.message)
                    this.props.getUserDetails(dates);
                    this.props.getScheduleData();
                }
            });
        });
    };

    openRoleLocation = (row, value) => {
        this.setState({
            // assignLocationData: row.total_payroll_assign_user_list,
            // locationLoader: false,
            usersNameData: row,
            activepage: value,
            locationRoleModal: true,
            locationLoader: true
        })
        // if (value === "holiday") {
        //   this.setState({
        //     assignLocationData: row.total_holidays_list,
        //     locationLoader: false
        //   })
        // }
        // if (value === "location") {
        //   this.setState({
        //     assignLocationData: row.total_location_list,
        //     locationLoader: false
        //   })
        // }
        // if (value === "userList") {
        //   this.setState({

        //   })
        // }

    }

    closelocationroleModal = () => {
        this.setState({
            locationRoleModal: false
        })
    }



    handleCollapse = () => {
        this.setState({
            openNow: !this.state.openNow
        })
    }


    render() {
        const { isLoading, classes, pageNo, rowsPerPage, permissionsData, LocationDetaildata, Activity_log, AprroveData } = this.props;
        if (isLoading) return <LoadingData />
        var approval_one;
        var approval_two;
        var approval_three;
        var isShowButton_one;
        var isShowButton_two;
        var isShowButton_three;
        var approval_name;
        var approval_name_two;
        var approval_name_three;
        var userCountLevel1;
        var userCountLevel2;
        var userCountLevel3;
        var user_list1;
        var user_list2;
        var user_list3;
        var dates = this.state.getDate ? this.state.getDate.monthDate : this.props.MonthData?.[0].monthDate;
        // var userCount = this.state.AprroveData.find((itemss) => itemss.approve_payroll_level === 1 ? itemss.userCount : "");
        // console.log("userCount",userCount)
        AprroveData && AprroveData.map((item) => {
            if (item.approve_payroll_level === 1) {
                approval_one = item.approve_payroll_level_status
                approval_name = item?.approved_by_uid?.user_name
                isShowButton_one = item?.isShowButton
                userCountLevel1 = item?.level_count
                user_list1 = item?.level_users
            }
            if (item.approve_payroll_level === 2) {
                approval_two = item.approve_payroll_level_status
                approval_name_two = item?.approved_by_uid?.user_name
                isShowButton_two = item?.isShowButton
                userCountLevel2 = item?.level_count
                user_list2 = item?.level_users
            }
            if (item.approve_payroll_level === 3) {
                approval_three = item.approve_payroll_level_status
                approval_name_three = item?.approved_by_uid?.user_name
                isShowButton_three = item?.isShowButton
                userCountLevel3 = item?.level_count
                user_list3 = item?.level_users
            }
        })

        return (
            <Grid container spacing={2} className={classes.root}>
                <Grid item xs={12}>
                    <Grid item xs={8} style={{ float: "right" }}>
                        <div style={{ display: "flex" }}>

                            {this.props.items &&

                            <ExportCsv
                                locationName={"locationName"}
                                printDate={moment(this.state.child_startDate).locale('en-US').format("ddd MMM DD") + "to" + moment(this.state.child_endDate).locale('en-US').format("ddd MMM DD")}
                                rowData={this.props.usersData}
                                items={this.props.items && this.props.items }
                            />}

                            {/* <ExportPE
                                locationName={"locationName"}
                                printDate={moment().locale('en-US').format("ddd MMM DD") + "to" + moment().locale('en-US').format("ddd MMM DD")}
                                rowData={this.state.payrollPEData}
                            /> */}

                            {/* <Button  className={classes.Download} onClick={this.props.openDownloadModal}>
                                Download
                            </Button> */}

                            <ExportCash
                                rowData={this.state.payrollCashData}
                                lid={this.props.lid}
                                dates={this.props.dates}
                                date={this.props.date}
                                valueSeclect={this.props.valueSeclect}

                            />

                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.props.Employechecked === true ?  true : false}
                                            onChange={this.props.handleChangeCheckBox}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />}
                                    label="Hide employees with 0 Hours" />
                            </FormGroup>

                        </div>


                        <Button className={classes.Download} style={{ display: 'none' }}>
                            Pay Minimum
                        </Button>
                    </Grid>
                </Grid>
                <Box className={classes.hidscroll} style={{
                    overflowX: 'scroll', overflowY: 'hidden',
                }} >
                    <SlickSilder MonthData={this.props.MonthData} showNewCard={this.props.showNewCard}
                        is_select_card={this.props.is_select_card}
                        date={this.props.date}
                        clickCard={this.props.clickCard} getData={this.getData} />
                </Box>

                <Grid item xs={12} className='payrollMain'>
                    <Grid container >
                        <Grid item xs={2} style={{
                            padding: "8px",
                            borderRight: "1px solid gray"
                        }}>
                            <Grid container>
                                <Grid item>
                                    <Typography variant="h6">
                                        {this.state.getDate ? this.state.getDate.Month : this.props.MonthData && this.props.MonthData[0].Month} Payroll
                                    </Typography>
                                    <Typography
                                        style={{ color: '#707070', fontSize: '15px' }}
                                        variant="body1">
                                        {this.state.getDate ? this.state.getDate.Date : this.props.MonthData && this.props.MonthData[0].Date} ({`${this.props.payrollData?.MonthDays} Days`})
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={10}>
                            <Grid container>
                                <Grid item xs={8 / 12} style={{
                                    padding: "8px 5px",
                                    borderRight: "1px solid gray"
                                }}>
                                    <Typography variant="body1" style={{ fontSize: "12px", fontWeight: 800, color: "black" }}>
                                        Calendar Days
                                    </Typography>
                                    <Typography variant="body1" style={{ fontSize: "12px", color: "#707070", fontWeight: 700 }}>
                                        {this.props.payrollData?.Calendar_Days}  Days
                                    </Typography>
                                </Grid>

                                <Grid item xs={8 / 12}
                                    onClick={() => history.push(`/hr/payroll`)}
                                    style={{
                                        cursor: 'pointer',
                                        padding: "8px 5px",
                                        borderRight: "1px solid gray"
                                    }}>
                                    <Typography variant="body1" style={{ fontSize: "12px", fontWeight: 800, color: "black" }}>
                                        Unmapped Employees
                                    </Typography>
                                    <Link style={{ fontSize: "12px" }}>
                                        {this.props.payrollData?.Unmap_Employees}
                                    </Link>
                                </Grid>

                                <Grid item xs={8 / 12}
                                    style={{
                                        padding: "8px 5px",
                                        borderRight: "1px solid gray"
                                    }}>
                                    <Typography variant="body1" style={{ fontSize: "12px", fontWeight: 800, color: "black" }}>
                                        Employees
                                    </Typography>
                                    <Typography variant="body1" style={{ fontSize: "12px", color: "#707070", fontWeight: 700 }}>
                                        {this.props.payrollData?.Employees}
                                    </Typography>
                                </Grid>

                                <Grid item xs={8 / 12}
                                    style={{
                                        padding: "8px 5px",
                                        borderRight: "1px solid gray"
                                    }}>
                                    <Typography variant="body1" style={{ fontSize: "12px", fontWeight: 800, color: "black" }}>
                                        Total Paid hours
                                    </Typography>
                                    <Typography variant="body1" style={{ fontSize: "12px", color: "#707070", fontWeight: 700 }}>
                                        {this.props.payrollData?.Total_Paid_hours}
                                    </Typography>
                                </Grid>

                                <Grid item xs={8 / 12}
                                    style={{
                                        padding: "8px 5px",
                                        borderRight: "1px solid gray"
                                    }}>
                                    <Typography variant="body1" style={{ fontSize: "12px", fontWeight: 800, color: "black" }}>
                                        Correction Request
                                    </Typography>
                                    <Typography variant="body1" style={{ fontSize: "12px", color: "#707070", fontWeight: 700 }}>
                                        {this.props.payrollData?.Correction_resolve} / {this.props.payrollData?.Correction_Request} <WarningIcon style={{ color: '#fea400' }} />
                                    </Typography>
                                </Grid>

                                <Grid item xs={8 / 12}
                                    style={{
                                        minWidth: "120px",
                                        padding: "8px 0px 8px 5px",
                                        borderRight: "1px solid gray"
                                    }}>
                                    <Typography variant="body1" style={{ fontSize: "12px", fontWeight: 800, color: "black" }}>
                                        Hours Summary
                                    </Typography>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <Typography variant="body1" style={{ fontSize: "12px", color: "#707070", fontWeight: 700 }}>
                                                Type
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body1" style={{ fontSize: "12px", color: "#707070", fontWeight: 700 }}>
                                                Hours
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body1" style={{ fontSize: "12px", color: "#707070", fontWeight: 700 }}>
                                                User
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container>
                                        <Grid item xs={4}>
                                            <Typography variant="body1" style={{ fontSize: "12px", color: "#707070", fontWeight: 500 }}>
                                                cash
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body1" style={{ fontSize: "12px", color: "#707070", fontWeight: 500 }}>
                                                10
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body1" style={{ fontSize: "12px", color: "#707070", fontWeight: 500 }}>
                                                10
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={8 / 12}
                                    style={{
                                        minWidth: "190px",
                                        padding: "8px 0px 8px 5px",
                                        borderRight: "1px solid gray"
                                    }}>
                                    <Typography variant="body1" style={{ fontSize: "12px", fontWeight: 800, color: "black" }}>
                                        Flags Summary
                                    </Typography>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <Typography variant="body1" style={{ fontSize: "12px", color: "#707070", fontWeight: 700 }}>
                                                Type
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="body1" style={{ fontSize: "12px", color: "#707070", fontWeight: 700 }}>
                                                Pending
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="body1" style={{ fontSize: "12px", color: "#707070", fontWeight: 700 }}>
                                                Fixed
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="body1" style={{ fontSize: "12px", color: "#707070", fontWeight: 700 }}>
                                                Ignored
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container>
                                        <Grid item xs={3}>
                                            <Typography variant="body1" style={{ fontSize: "12px", color: "#707070", fontWeight: 500 }}>
                                                break
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="body1" style={{ fontSize: "12px", color: "#707070", fontWeight: 500 }}>
                                                5
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="body1" style={{ fontSize: "12px", color: "#707070", fontWeight: 500 }}>
                                                10
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="body1" style={{ fontSize: "12px", color: "#707070", fontWeight: 500 }}>
                                                10
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>


                                <Grid item xs={8 / 12}
                                    style={{
                                        minWidth: "120px",
                                        padding: "8px 0px 8px 5px"
                                    }}>
                                    <Typography variant="body1" style={{ fontSize: "12px", fontWeight: 800, color: "black" }}>
                                        Public Holidays
                                    </Typography>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <Typography variant="body1" style={{ fontSize: "12px", color: "#707070", fontWeight: 700 }}>
                                                Count
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body1" style={{ fontSize: "12px", color: "#707070", fontWeight: 700 }}>
                                                Users
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body1" style={{ fontSize: "12px", color: "#707070", fontWeight: 700 }}>
                                                Hours
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container>
                                        <Grid item xs={4}>
                                            <Typography variant="body1" style={{ fontSize: "12px", color: "#707070", fontWeight: 500 }}>
                                                {this.props.payrollData?.Public_Holidays_count}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body1" style={{ fontSize: "12px", color: "#707070", fontWeight: 500 }}>
                                                {this.props.payrollData?.Holiday_user_count}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body1" style={{ fontSize: "12px", color: "#707070", fontWeight: 500 }}>
                                                {this.props.payrollData?.Public_Holiday_hrs}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className="approvalMain" alignItems="center">
                    {LocationDetaildata?.payrun_1_level_approval === 1 ?
                        <Grid item>
                            <div className="approvalIcon">
                                {permissionsData.level_1_approval ?
                                    isShowButton_one === 1 ?
                                        <>
                                            <CheckCircleIcon style={{ color: 'green', cursor: 'pointer' }} onClick={() => this.onApprovalClick(dates, 1)} />
                                            {/* <CancelIcon style={{ color: '#b71c1c', cursor: 'pointer' }} onClick={() => this.onRejectClick(dates, 1)} /> */}
                                        </> : "" : ""
                                }
                            </div>
                            <Grid item className={`${LocationDetaildata?.payrun_2_level_approval === 1 ? 'wrapper' : ""}`}>
                                <Button className="inst-btn"
                                    style={{ backgroundColor: approval_one === 1 ? "#0F640D" : approval_one === 2 ? '#b71c1c' : 'gainsboro', color: approval_one === 1 ? "white" : approval_one === 2 ? "white" : '#263238' }}>
                                    Level 1 Approval</Button>
                            </Grid>
                            {/* {LocationDetaildata?.payrun_1_level_approval === 1 ? */}
                            <p
                            >{`${approval_one === 1 ? "Approve By " + approval_name : approval_one === 2 ? "Rejected By " + approval_name : ""}`}</p>

                            <p style={{ height: "26px", cursor: "pointer", textAlign: "left", color: schedulerColor.main, margin: "8px", marginLeft: "44px" }} onClick={() => this.openRoleLocation(user_list1)}
                            >{`${"User " + userCountLevel1}`}</p>

                        </Grid>
                        : ""}

                    {LocationDetaildata?.payrun_2_level_approval === 1 ?
                        <Grid item>
                            <div className="approvalIcon">
                                {permissionsData.level_2_approval ?
                                    isShowButton_two === 1 ?
                                        <>
                                            <CheckCircleIcon style={{ color: 'green', cursor: 'pointer' }} onClick={() => this.onApprovalClick(dates, 2)} />
                                            {/* <CancelIcon style={{ color: '#b71c1c', cursor: 'pointer' }} onClick={() => this.onRejectClick(dates, 2)} /> */}
                                        </> : "" : ""
                                }
                            </div>

                            <Grid item className={`${LocationDetaildata?.payrun_3_level_approval === 1 ? 'wrapper' : ""}`}>
                                <Button className="inst-btn" style={{ backgroundColor: approval_two === 1 ? "#0F640D" : approval_two === 2 ? '#b71c1c' : 'gainsboro', color: approval_two === 1 ? "white" : approval_two === 2 ? "white" : '#263238' }}>
                                    Level 2 Approval</Button>
                            </Grid>
                            {/* {LocationDetaildata?.payrun_2_level_approval === 2 ? */}
                            <p>
                                {`${approval_two === 1 ? "Approve By " + approval_name_two : approval_two === 2 ? "Rejected By " + approval_name_two : ""}`}</p>
                            <p style={{ height: "26px", cursor: "pointer", textAlign: "left", color: schedulerColor.main, margin: "8px", marginLeft: "44px" }} onClick={() => this.openRoleLocation(user_list2)}
                            >{`${"User " + userCountLevel2}`}</p>

                        </Grid>
                        : ""}
                    {LocationDetaildata?.payrun_3_level_approval === 1 ?
                        <Grid item>
                            <div className="approvalIcon">
                                {permissionsData.level_3_approval ?
                                    isShowButton_three === 1 ?
                                        <>
                                            <CheckCircleIcon style={{ color: 'green', cursor: 'pointer' }} onClick={() => this.onApprovalClick(dates, 3)} />
                                            {/* <CancelIcon style={{ color: '#b71c1c', cursor: 'pointer' }} onClick={() => this.onRejectClick(dates, 3)} /> */}
                                        </> : "" : ""
                                }
                            </div>
                            <Grid item>
                                <Button className="inst-btn" style={{ backgroundColor: approval_three === 1 ? "#0F640D" : approval_three === 2 ? '#b71c1c' : 'gainsboro', color: approval_three === 1 ? "white" : approval_three === 2 ? "white" : '#263238' }}>Level 3 Approval</Button>
                            </Grid>

                            <p >
                                {`${approval_three === 1 ? "Approve By " + approval_name_three : approval_three === 2 ? "Rejected By " + approval_name_three : ""}`}</p>
                            <p style={{ height: "26px", cursor: "pointer", textAlign: "center", color: schedulerColor.main, margin: "8px" }} onClick={() => this.openRoleLocation(user_list3)}
                            >
                                {`${"User " + userCountLevel3}`}</p>

                        </Grid>
                        : ""}
                    <Grid item style={{ marginLeft: "12px", marginBottom: "22px" }} >
                        {LocationDetaildata?.payrun_1_level_approval === 1 &&
                            <Button className="inst-btns" onClick={() => this.onClickReset(dates)}>Reset</Button>
                        }
                    </Grid>
                </Grid>
                <Grid item sm={12}>
                    <Paper style={{ marginTop: 10 }}>

                        <TableContainer
                        // onClick={this.handleCollapse}
                        >
                            <Table

                                className={classes.table}
                                size='medium'
                            >

                                <TableHead>
                                    <Tooltip arrow title="Edit" className="editIcon">
                                        <IconButton size="small" onClick={this.props.openEditTableModal}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <TableRow>
                                        {/* <TableCell className={classes.headCell}>
                                            <IconButton
                                                aria-label="expand row"
                                                size="small"
                                               
                                            >
                                                {this.state.openNow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>
                                        </TableCell> */}
                                        {this.props.items?.map((headCell) => (
                                            headCell.status === true ?
                                                <TableCell
                                                    key={headCell.id}
                                                    sortDirection={this.state.orderBy === headCell.id ? this.state.order : false}
                                                >

                                                    <TableSortLabel
                                                        className={classes.headCell}
                                                        active={this.state.orderBy === headCell.id}
                                                        direction={this.state.orderBy === headCell.id ? this.state.order : 'asc'}
                                                        onClick={this.createSortHandler(headCell.id)}
                                                    >
                                                        {headCell.content}
                                                        {this.state.orderBy === headCell.id ? (
                                                            <span className={classes.visuallyHidden}>
                                                                {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                            </span>
                                                        ) : null}
                                                    </TableSortLabel>
                                                </TableCell>
                                                : ""

                                        ))}
                                    </TableRow>

                                </TableHead>


                                {this.state.payrollLoading || this.props.payrollLoadingCard ?
                                    //  <Collapse in={this.state.openNow} timeout="auto" unmountOnExit>
                                    <TableBody>
                                        <LoadingData />
                                    </TableBody>
                                    // {/* </Collapse> */}
                                    :
                                    // <Collapse  colSpan={12} fullWidth in={this.state.openNow} timeout="auto" unmountOnExit>
                                    <TableBody  >

                                        {stableSort(this.props.usersData, getComparator(this.state.order, this.state.orderBy))
                                            .map((row, index) => {
                                                    return (
                                                        <TableRow hover tabIndex={-1} key={row.uid}>
                                                        {
                                                            this.props.items?.map((childSeq) => (                                                   
                                                                // childSeq.status === true ?
                                                                    childSeq.content === "Username"?
                                                                        <TableCell align="left"
                                                                            className={classes.TableCell}>
                                                                            <Typography className={classes.user_name}
                                                                                style={{ color: '#323C47' }} >
                                                                                {row.user_name}
                                                                            </Typography>
                                                                        </TableCell> 
                                                                        :childSeq.pi_id &&
                                                                          childSeq.content ? <TableCell>
                                                                            {row[`${childSeq.content.replaceAll(" ","_")}_total_hrs`]}
                                                                            
                                                                          </TableCell> 
                                                                        : childSeq.content === "Role" ? <TableCell align="left" className={classes.TableCell}>
                                                                            {row.user_role}
                                                                        </TableCell>
                                                                           : childSeq.content === "Template Name" ? <TableCell align="left" className={classes.TableCell}>
                                                                           {row.template_name}
                                                                       </TableCell>
                                                                        :childSeq.content === "Payroll Rule" ? <TableCell align="left" className={classes.TableCell}  >
                                                                                {row.payroll_rule}
                                                                            </TableCell>
                                                                            : childSeq.content === "Public Holiday" ? <TableCell align="left" className={classes.TableCell}  >
                                                                                {row.isconected_holiday ? <div style={{color:"green"}}>Yes</div> : <div style={{color:"red"}}>No</div>}
                                                                            </TableCell>
                                                                              : childSeq.content === "Employee ID" ? <TableCell align="left" className={classes.TableCell}  >
                                                                              {row.mapped_code}
                                                                          </TableCell>
                                                                                : childSeq.content === "Scheduled Hrs" ? <TableCell align="left" className={classes.TableCell}>
                                                                                    {row.total_scheduled_hrs}
                                                                                </TableCell>
                                                                                    : childSeq.content === "Actual Hours" ? <TableCell align="left" className={classes.TableCell}>
                                                                                        {row.total_actual_hrs}
                                                                                    </TableCell>
                                                                                        : childSeq.content === "Paid Hours" ? <TableCell align="left" className={classes.TableCell}>
                                                                                            {row.total_paid_hrs}
                                                                                        </TableCell>
                                                                                            : childSeq.content === "Unsch hours" ? <TableCell align="left" className={classes.TableCell}>
                                                                                                {row.unsch_hrs}
                                                                                            </TableCell>
                                                                                                : childSeq.content === "Break diff" ? <TableCell align="left" className={classes.TableCell}>
                                                                                                    {row.total_break_hrs}
                                                                                                </TableCell>
                                                                                                    : childSeq.content === "Estimated Wages" ? <TableCell align="left" className={classes.TableCell}>
                                                                                                        ${row.total_est_wages}
                                                                                                    </TableCell>
                                                                                                        : childSeq.content === "Manual hours" ? <TableCell align="left" className={classes.TableCell}>
                                                                                                            {row.manual_hours}
                                                                                                        </TableCell>
                                                                                                            : childSeq.content === "Correction Req" ? <TableCell align="left" className={classes.TableCell} styel={{ textDecoration: "underline" }}>
                                                                                                                {row.correction_request !== 0 ?
                                                                                                                    <>  {row.correction_request}
                                                                                                                        <WarningIcon style={{ color: '#fea400', cursor: 'pointer' }}
                                                                                                                            onClick={() => this.getCorrectionWarning(dates, row.uid)} /> </>
                                                                                                                    : row.correction_request}
                                                                                                            </TableCell>
                                                                                                                : childSeq.content === "OverTime Hours" ? <TableCell align="left" className={classes.TableCell}>
                                                                                                                    {row.overtime_hours}
                                                                                                                </TableCell>
                                                                                                                    : childSeq.content === "Regular Hrs" ? <TableCell align="left" className={classes.TableCell}>
                                                                                                                        {row.regular_hours}
                                                                                                                    </TableCell>
                                                                                                                        : childSeq.content === "Holiday Hrs" ? <TableCell align="left" className={classes.TableCell}>
                                                                                                                            {row.holiday_hours}
                                                                                                                        </TableCell>
                                                                                                                            :
                                                                                                                            <TableCell align="left" className={classes.TableCell}>
                                                                                                                            -
                                                                                                                          </TableCell>
                                                                                                                            //  : ""
                                                            ))
                                                        }
                                                    </TableRow>

                                                    )
                                                

                                            })}

                                        {this.props.usersData && this.props.usersData.length < 1 ?
                                            <TableRecordNotFound
                                                colSpan={8}
                                                label="No Data Found.."
                                            />
                                            : ""
                                        }

                                    </TableBody>
                                    // </Collapse>
                                }



                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>

                {/* <Grid item xs={12} className="approvalMain" alignItems="center">
                    {LocationDetaildata?.payrun_1_level_approval === 1 ?
                        <Grid item>
                            <div className="approvalIcon">
                                {permissionsData.level_1_approval ?
                                    isShowButton_one === 1 ?
                                        <>
                                            <CheckCircleIcon style={{ color: 'green', cursor: 'pointer' }} onClick={() => this.onApprovalClick(dates, 1)} />
                                            <CancelIcon style={{ color: '#b71c1c', cursor: 'pointer' }} onClick={() => this.onRejectClick(dates, 1)} />
                                        </> : "" : ""
                                }
                            </div>
                            <Grid item className={`${LocationDetaildata?.payrun_2_level_approval === 1 ? 'wrapper' : ""}`}>
                                <Button className="inst-btn"
                                    style={{ backgroundColor: approval_one === 1 ? "#0F640D" : approval_one === 2 ? '#b71c1c' : 'gainsboro', color: approval_one === 1 ? "white" : approval_one === 2 ? "white" : '#263238' }}>
                                    Level 1 Approval</Button>
                            </Grid>
                            {LocationDetaildata?.payrun_1_level_approval === 1 ?
                                <p style={{ height: "26px" }}
                                >{`${approval_one === 1 ? "Approve By " + approval_name : approval_one === 2 ? "Rejected By " + approval_name : ""}`}</p>
                                :
                                <p style={{ height: "26px" }} onClick={() => this.openRoleLocation()}
                                >{`${"User " + userCount}`}</p>
                            }
                        </Grid>
                        : ""}

                    {LocationDetaildata?.payrun_2_level_approval === 1 ?
                        <Grid item>
                            <div className="approvalIcon">
                                {permissionsData.level_2_approval ?
                                    isShowButton_two === 1 ?
                                        <>
                                            <CheckCircleIcon style={{ color: 'green', cursor: 'pointer' }} onClick={() => this.onApprovalClick(dates, 2)} />
                                            <CancelIcon style={{ color: '#b71c1c', cursor: 'pointer' }} onClick={() => this.onRejectClick(dates, 2)} />
                                        </> : "" : ""
                                }
                            </div>

                            <Grid item className={`${LocationDetaildata?.payrun_3_level_approval === 1 ? 'wrapper' : ""}`}>
                                <Button className="inst-btn" style={{ backgroundColor: approval_two === 1 ? "#0F640D" : approval_two === 2 ? '#b71c1c' : 'gainsboro', color: approval_two === 1 ? "white" : approval_two === 2 ? "white" : '#263238' }}>
                                    Level 2 Approval</Button>
                            </Grid>
                            {LocationDetaildata?.payrun_1_level_approval === 2 ?
                                <p style={{ height: "26px" }}>
                                    {`${approval_two === 1 ? "Approve By " + approval_name_two : approval_two === 2 ? "Rejected By " + approval_name_two : ""}`}</p>
                                : <p style={{ height: "26px" }} onClick={() => this.openRoleLocation()}
                                >{`${"User " + userCount}`}</p>
                            }
                        </Grid>
                        : ""}
                    {LocationDetaildata?.payrun_3_level_approval === 1 ?
                        <Grid item>
                            <div className="approvalIcon">
                                {permissionsData.level_3_approval ?
                                    isShowButton_three === 1 ?
                                        <>
                                            <CheckCircleIcon style={{ color: 'green', cursor: 'pointer' }} onClick={() => this.onApprovalClick(dates, 3)} />
                                            <CancelIcon style={{ color: '#b71c1c', cursor: 'pointer' }} onClick={() => this.onRejectClick(dates, 3)} />
                                        </> : "" : ""
                                }
                            </div>
                            <Grid item>
                                <Button className="inst-btn" style={{ backgroundColor: approval_three === 1 ? "#0F640D" : approval_three === 2 ? '#b71c1c' : 'gainsboro', color: approval_three === 1 ? "white" : approval_three === 2 ? "white" : '#263238' }}>Level 3 Approval</Button>
                            </Grid>
                            {LocationDetaildata?.payrun_1_level_approval === 2 ?
                                <p style={{ height: "26px" }}>
                                    {`${approval_three === 1 ? "Approve By " + approval_name_three : approval_three === 2 ? "Rejected By " + approval_name_three : ""}`}</p>
                                : <p style={{ height: "26px" }} onClick={() => this.openRoleLocation()}
                                >{`${"User " + userCount}`}</p>
                            }
                        </Grid>
                        : ""}
                    <Grid item style={{ marginLeft: "12px", marginBottom: "9px" }} >
                        <Button className="inst-btns" onClick={() => this.onClickReset(dates)}>Reset</Button>
                    </Grid>
                </Grid> */}

                <h4>Activity Log </h4>
                {Activity_log && Activity_log.length > 0 ? Activity_log.map((item) => {
                    return (
                        <Grid item xs={12} className="ActiveLogMain" style={{ paddingTop: 0 }}>
                            <Grid item xs={2} md={2} lg={2} sm={2} >
                                <Button className="Account-btn">{moment(item.payroll_approve_date).locale('en-US').format("DD-MMM-YYYY hh:mm a")}</Button>
                                <div className="ActiveverticalLine" />
                                {/* <Button className="Account-btn">{moment(item.payroll_approve_date).locale('en-US').format("DD-MMM-YYYY")}</Button> */}
                            </Grid>
                            <Grid item xs={10} md={10} lg={10} sm={10}>
                                <div className="ActiveTitle">{item.approved_user}
                                    {/* @ {moment(item.payroll_approve_date).locale('en-US').format("hh:mm a")} */}
                                </div>
                                <div className="ActiveTitle">Status Changed : {item.status}</div>
                                {item.Comment.length > 0 &&
                                    <p className="ActiveComment">
                                        Comment :
                                    </p>}
                                {item.Comment && item.Comment.map((it) => {
                                    return (
                                        <div dangerouslySetInnerHTML={{ __html: it.comment }} />
                                    )
                                })}

                                {
                                    item.isallowcomment === 1 ?
                                        <div className={classes.textareaContainer}>
                                            <MyEditor
                                                value={this.state.commentsData} handleEditor={this.onChangeComment}
                                            />
                                            <button className={classes.textareacontainerbutton}
                                                onClick={() => this.payrollComment(item?._id, dates)}>Send</button>
                                        </div> : ''}
                            </Grid>
                        </Grid>
                    )
                }) :
                    <Grid sm="10" style={{ textAlign: 'center', marginBottom: '2rem', marginTop: '2rem', }}>
                        {/* <img src={noactvtylog} width='400' alt="noactvtylog" /> */}
                        <div className={classes.textareaContainer}>
                            {/* <textarea name="commentData" className={classes.textareacontainertextarea} onChange={this.onChangeComment}
                                                value={this.state.commentsData} placeholder="Add Comment"></textarea>
                                           
                                            <button className={classes.textareacontainerbutton}
                                                // onClick={() => this.payrollComment(item?._id, dates)}
                                                >
                                            Send</button> */}
                            <MyEditor
                                value={this.state.commentsData} handleEditor={this.onChangeComment}
                            />
                            <button className={classes.textareacontainerbutton}
                                onClick={() => this.payrollComment(null, dates)}
                            >
                                Send</button>
                        </div>
                    </Grid>
                }

                {this.state.is_warning_modal === true &&
                    <WarningIconModal is_warning_modal={this.state.is_warning_modal}
                        warningModalClose={this.warningModalClose}
                        isLoading={this.state.isLoading}
                        correctionWarningData={this.state.correctionWarningData}
                    />}

                {this.props.EditTableModal === true &&
                    <EditTableModal
                        closeEditTableModal={this.props.closeEditTableModal}
                        EditTableModal={this.props.EditTableModal}
                        onDragEnd={this.props.onDragEnd} items={this.props.items}
                        saveCloumnRow={this.props.saveCloumnRow}
                        handleChangeChecked={this.props.handleChangeChecked}
                    />}

                <Modal
                    open={this.state.locationRoleModal}
                    onClose={this.closelocationroleModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <Box sx={style}>
                        <UserListModal
                            AprroveData={AprroveData}
                            user_list={this.state.usersNameData}
                            pageNo={pageNo}
                            rowsPerPage={rowsPerPage}
                        />

                    </Box>
                </Modal>

                <Modal
                    open={this.props.downloadModal}
                    onClose={this.props.closeDownloadModal}
                >
                    <Box sx={styleModal}>
                        <Grid container spacing={2}>
                            <Grid item md={12} style={{ display: "flex" }}>
                                <Grid item md={6}>
                                    <Typography id="modal-modal-title" variant="h6" component="h6" style={{ fontWeight: 700 }}>
                                        Payment Method :
                                    </Typography>
                                </Grid>
                                <Grid item md={6}>
                                    <FormControl sx={{ m: 1, minWidth: 120, marginTop: "auto" }} size="small" error>
                                        <Select
                                            labelId="demo-controlled-open-select-label"
                                            id="demo-controlled-open-select"
                                            value={this.props.valueSeclect}
                                            label="Download"
                                            style={{ width: "200px", border: "1px solid gray", borderRadius: "5px" }}
                                            onChange={this.props.handleChange}
                                        >
                                            {this.state.payrollCashData && this.state.payrollCashData.map((row) => {
                                                return (
                                                    <MenuItem
                                                        value={row}
                                                        style={{ textDecoration: "none", color: "black" }}>
                                                        {row.name}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>

                            </Grid>
                            <Grid item md={12}>
                                <Typography className={classes.show} style={{ marginTop: -10 }}>
                                    <Checkbox
                                        name="work_date"
                                        //value={displayData.display[0]?.showOnHomePage}
                                        checked={this.props.dates.work_date}
                                        onChange={(e) => this.props.handleChangeShowMenu(e)}
                                        className={classes.discussion} /> Work Date
                                </Typography>
                            </Grid>

                            <Grid item md={12}>
                                <Typography className={classes.show} style={{ marginTop: -10 }}>
                                    <Checkbox
                                        name="period_start"
                                        //value={displayData.display[0]?.showOnHomePage}
                                        checked={this.props.dates.period_start}
                                        onChange={(e) => this.props.handleChangeShowMenu(e)}
                                        className={classes.discussion} /> Period start/End
                                </Typography>
                            </Grid>

                            {/* <Grid item md={12}>
                            <Typography className={classes.show} style={{ marginTop: -10 }}>
                                <Checkbox
                                name="period_end"
                                //value={displayData.display[0]?.showOnHomePage}
                                checked={this.props.dates.period_end}
                                onChange={(e) => this.props.handleChangeShowMenu(e)}
                                className={classes.discussion} /> Period End
                            </Typography>
                            </Grid> */}

                            <Grid item md={12} style={{ textAlign: "center" }}>
                                <ExportDownload
                                    rowData={this.props.downloaduserdata}
                                    lid={this.props.lid}
                                    dates={this.props.dates}
                                    date={this.props.date}
                                    valueSeclect={this.props.valueSeclect}
                                    closeDownloadModal={this.props.closeDownloadModal}
                                />
                            </Grid>
                        </Grid>

                    </Box>
                </Modal>
            </Grid>
        )
    }
}

const mapStateToProps = (state) => ({
    permissionsData: state.settingsRoles.permissionsData,
    LocationDetaildata: state.schedulerSettingsLocationdata.LocationDetaildata.locationDetail,
});

const ConnectWith = connect(mapStateToProps, { userPermissions, fetchData, getScheduleData })(Company);
export default withStyles(styles)(ConnectWith);

