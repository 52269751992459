import { MoNGO_DB_API_URL, HEADER_TOKEN } from "../../../../config";
import axios from 'axios';

export const timesheetServices = {
    fetchData,
    addTimecard,
    editTimecard,
    viewTimecard,
    approveRevisedHours,
    approveBreaks,
    toggleTimesheetIssue,
    fetchPayrollTimecode
}

function fetchData(data) {
    return axios.post(`${MoNGO_DB_API_URL}/scheduler/timesheet/new`, { data }, HEADER_TOKEN).then(response => response)
};

function toggleTimesheetIssue(data) {
    return axios.post(`${MoNGO_DB_API_URL}/scheduler/timesheet/toogle_issue`, { data }, HEADER_TOKEN).then(response => response)
};

function addTimecard(data) {
    return axios.post(`${MoNGO_DB_API_URL}/scheduler/timecard/add/newtimecard`, { data }, HEADER_TOKEN).then(response => response)
};

function editTimecard(data) {
    return axios.put(`${MoNGO_DB_API_URL}/scheduler/timecard/edit/newtimecard`, { data }, HEADER_TOKEN).then(response => response)
};

function approveRevisedHours(data) {
    return axios.put(`${MoNGO_DB_API_URL}/scheduler/timecard/approve/revised`, { data }, HEADER_TOKEN).then(response => response)
};

function approveBreaks(data) {
    return axios.put(`${MoNGO_DB_API_URL}/scheduler/timecard/approve/breaks`, { data }, HEADER_TOKEN).then(response => response)
};

function viewTimecard(myItem) {
    return axios.get(`${MoNGO_DB_API_URL}/scheduler/timecard/viewpayroll/${myItem.job_id}/${myItem.currentDate}`, HEADER_TOKEN).then(response => response)
};

function fetchPayrollTimecode() {
    return axios.get(`${MoNGO_DB_API_URL}/payroll_timecode`, HEADER_TOKEN).then(response => response)
};
